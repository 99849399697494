import React, { useContext, useState } from "react";
export const OrderTabContext = React.createContext();

const GroupActionStoreContext = React.createContext();

export const useGroupActionStoreContext = () => {
    return useContext(GroupActionStoreContext);
}

export const GroupActionStoreProvider = ({children}) => {
    const [selectedStoreIds, setSelectedStoreIds] = useState([]);
   return (
       <GroupActionStoreContext.Provider value={{selectedStoreIds, setSelectedStoreIds}}>
            {children}
        </GroupActionStoreContext.Provider>
   );
}
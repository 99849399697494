import { useState} from "react";
import { useDispatch, useSelector } from "react-redux"
import { setOpenModal } from "../../actions/general";
import { ModalType } from "../../helpers/modals";
import { DefaultButton } from "../buttons/DefaultButton";
import { DetailActionButton } from "../buttons/DetailActionButton";
import { DefaultInput } from "../inputs/DefaultInput";

export const GroupActionDailyLimitModal = () => {
    const openModal = useSelector((state) => state.general.openModal);
    const modalParams = useSelector((state) => state.general.modalParams);
    const [dailyAmountAvailable, setDailyAmountAvailable] = useState(3);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(setOpenModal(null));
    }

    const outsideClick = (e) => {
        if (e.target === e.currentTarget) closeModal();
    }

    const submit = () => {
        if (modalParams.onConfirm) {
            modalParams.onConfirm(parseInt(dailyAmountAvailable), (success) => {
                if (success) closeModal();
            });
        }
    }

    return openModal === ModalType.GroupActionDailyLimit ? (
        <div className="modal-wrapper" onClick={(e) => outsideClick(e)}>
            <div className="modal">
                <div className="modal-header">
                    <div className="title">
                        <span className="big bold">{modalParams.title ? modalParams.title : ""}</span>
                    </div>
                    <div className="actions">
                        <DetailActionButton name="exit" icon="x" onPress={() => closeModal()} />
                    </div>
                </div>
                <div className="modal-content">
                    <div className="inner-content">
                        <span>Bitte geben Sie den täglichen Bestand für Überraschungstüten-Produkte an:</span>
                        <DefaultInput name="Anzahl verfügbar pro Tag:" type="number" value={dailyAmountAvailable} onChange={(e) => setDailyAmountAvailable(e.target.value)} />
                    </div>

                    <div className="submit">
                        <DefaultButton text="Abbrechen" isFlat={true} onPress={() => closeModal()} />
                        <DefaultButton text="Weiter" onPress={() => submit()} />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}
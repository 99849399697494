import React, { useState } from "react";
import { Feather as Icon } from 'react-web-vector-icons';
import { ActivityIndicator } from "../loading/ActivityIndicator";

export const DropdownButton = ({ text, icon, loading, options }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={`dropdownButton${expanded ? ' expanded' : ''}`} onClick={() => setExpanded(!expanded)}>
      {loading ? <ActivityIndicator /> : (
        <div className="dropdownHeader">
          <Icon name={icon} color='var(--onSurfaceStrong)' size={20} />
          <span>{text}</span>
        </div>
      )}
      {expanded && <div className="dropdownContent">
        {options && options.map((o, i) => {
          return (
            <div key={"ddi" + i} className="dropdownItem" onClick={() => o.onPress ? o.onPress() : null}>
              <span>{o.name}</span>
            </div>
          )
        })}
      </div>}
    </div>
  )
}
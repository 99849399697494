import React, { useEffect, useState } from "react";
import { Sidemenu } from "../../components/sidemenu/Sidemenu";
import CacheRoute from 'react-router-cache-route';
import { useDispatch, useSelector } from "react-redux";
import { Stores } from "../Stores";
import { Products } from "../Products";
import "./../../style/Global.scss";
import { shadeColor } from "../../utils/color";
import { Support } from "../Support";
import { Settings } from "../Settings";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { listenForSocketEvents } from "../../socketio";
import { Settings as LuxonSettings } from 'luxon';
import { getCategoriesRequested } from "../../actions/product";
import { setDynamicManifest } from "../../data/dynamicManifest";
import { Orders } from "../Orders";
import { OrderSummary } from "../OrderSummary";
import { NotificationHandler } from "../../components/general/NotificationHandler";
import { Statistics } from "../Statistics";
import { GroupActions } from "../GroupActions";
import { GroupActionConfirmModal } from "../../components/modal/GroupActionConfirmModal";
import { GroupActionDailyLimitModal } from "../../components/modal/GroupActionDailyLimitModal";
import { RejectionReasonModal } from "../../components/modal/RejectionReasonModal";

export const AppContainer = () => {
  const { isTablet } = useWindowDimensions();
  const [sidemenuCollapsed, setSidemenuCollapsed] = useState(isTablet);
  const token = useSelector(state => state.authentication.token);
  const colors = useSelector(state => state.company.colors);
  const urlIdentifier = useSelector(state => state.company.urlIdentifier);
  const dispatch = useDispatch();

  useEffect(() => {
    LuxonSettings.defaultZoneName = "Europe/Berlin";
    dispatch(getCategoriesRequested());
    listenForSocketEvents(token, dispatch);
    // console.log(token)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSidemenuCollapsed(isTablet);
  }, [isTablet]);

  useEffect(() => {
    document.documentElement.style.setProperty("--primary", colors.primary);
    document.documentElement.style.setProperty("--primaryLight", shadeColor(colors.primary, 20));
    document.documentElement.style.setProperty("--primaryVeryLight", shadeColor(colors.primary, 40));
    document.documentElement.style.setProperty("--onPrimary", colors.onPrimary);
  }, [colors]);

  useEffect(() => {
    setDynamicManifest(urlIdentifier);
  }, [urlIdentifier]);

  return (
    <div className={`appWrapper${sidemenuCollapsed ? ' sidemenuCollapsed' : ''}`}>
      <Sidemenu sidemenuCollapsed={sidemenuCollapsed} setSidemenuCollapsed={setSidemenuCollapsed} isTablet={isTablet} />
      <NotificationHandler />
      <CacheRoute path={["/stores", "/stores/add", "/stores/added", "/store/:id", "/store/edit/:id"]} component={Stores} />
      <CacheRoute path={["/products", "/products/add", "/products/added", "/product/:id", "/product/edit/:id"]} component={Products} />
      <CacheRoute path={["/orders", "/order/:id"]} component={Orders} />
      <CacheRoute path={["/order-summaries", "/order-summary/:id"]} component={OrderSummary} />
      <CacheRoute path={["/group-actions", "/group-actions/order-settings", "/group-actions/special-days", "/group-actions/products"]} component={GroupActions} />
      <CacheRoute path={["/stats", "/stat/revenue", "/stat/orders"]} component={Statistics} />
      <CacheRoute path={["/support"]} component={Support} />
      <CacheRoute path={["/settings"]} component={Settings} />
      <GroupActionConfirmModal />
      <GroupActionDailyLimitModal />
      <RejectionReasonModal />
    </div>
  )
}
import React from "react";

export const SelectionInput = ({ name, data, value, onChange }) => {

  const options = [{ id: -1, name: "Bitte wählen" }, ...data];

  return (
    <div className="selectionInput">
      <span className="small">{name}</span>
      <select value={value} onChange={onChange}>
        {options.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
      </select>
    </div>
  )
}
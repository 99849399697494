import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { autoLoginStoreRequested } from "../../actions/authentication";
import { deleteStoreRequested } from "../../actions/store";
import { DefaultButton } from "../buttons/DefaultButton";
import { DetailActionButton } from "../buttons/DetailActionButton";
import { ActivityIndicator } from "../loading/ActivityIndicator";
import { OpeningHours } from "../text/OpeningHours";
import { VariableDisplay } from "../text/VariableDisplay";

export const StoreDetails = ({ match, history }) => {
  const selectedStoreId = parseInt(match.params.selectedStoreId);
  const store = useSelector(state => state.store.stores ? state.store.stores.filter(s => s.id === selectedStoreId)[0] : null);
  const [deletePressed, setDeletePressed] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();

  const featureString = store && store.features && store.features.reduce((a, c, i) => {
    if (i > 0) return a + ", " + c.name;
    return a + c.name;
  }, "");

  useEffect(() => {
    if (deletePressed) setDeletePressed(false); // Don't include deletePressed in deps -> would cause incorrect behaviour
  }, [selectedStoreId]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteStore = () => {
    setDeleteLoading(true);
    dispatch(deleteStoreRequested(selectedStoreId, handleDeleteResponse));
  }

  const handleDeleteResponse = (success) => {
    setDeleteLoading(false);
    if (success) history.push("/stores");
  }

  const handleAutoStoreLogin = () => {
    dispatch(autoLoginStoreRequested(selectedStoreId, (success) => {
      if (success) {
        const baseDomain = window.location.hostname.replace('company', 'store');
        window.open(`https://${baseDomain}/auto-login`, '_blank').focus();
      }
    }));
  }

  return store ? (
    <React.Fragment>
      <div className="detailHeader">
        <div className="detailHeaderTitle">
          <span className="big">Details zur Filiale</span>
        </div>
        <div className="detailHeaderActions">
          <React.Fragment>
            <DetailActionButton name="auto-login" icon="external-link" onPress={handleAutoStoreLogin} />
            <DetailActionButton name="edit" icon="edit" onPress={() => history.push("/store/edit/" + selectedStoreId)} />
            <DetailActionButton name="delete" icon="trash-2" onPress={() => setDeletePressed(true)} />
          </React.Fragment>
        </div>
      </div>
      <div className="detailContent">
        {deletePressed && <div className="insideModal">
          <span className="medium">Sind Sie sicher, dass Sie diese Filiale unwiderruflich löschen möchten?</span>
          <div className="insideModalActions">
            <DefaultButton text="Löschen" loading={deleteLoading} isDelete={true} onPress={() => deleteStore()} />
            {!deleteLoading && <DefaultButton text="Abbrechen" isFlat={true} onPress={() => setDeletePressed(false)} />}
          </div>
        </div>}
        <div className="store">
          <div className="storeInfoContainer">
            <VariableDisplay label="Name" value={store.name} marginBottom={true} />
            <VariableDisplay label="Adresse" value={`${store.address.street} ${store.address.streetNumber}, ${store.address.postalCode} ${store.address.city}`} marginBottom={true} />
            <VariableDisplay label="Telefon" value={store.phone} marginBottom={true} />
            <VariableDisplay
              label="Sichtbarkeit"
              value={store.disabled ? "Nicht öffentlich sichtbar" : "Öffentlich sichtbar"}
              marginBottom={true}
              trailingIcon={store.disabled ? "alert-circle" : null}
              trailingIconColor={"var(--error)"}
            />
            {store.features && store.features.length > 0 && <VariableDisplay label="Aktive Spezial-Features" value={featureString} marginBottom={true} />}
            <OpeningHours data={store.openingHours} />
          </div>
          <div className="storeImageContainer">
            {store.imageUrl ? <img src={store.imageUrl} alt="Bild der Filiale" /> : <div className="imgEmpty" />}
          </div>
        </div>
      </div>
    </React.Fragment >
  ) : <ActivityIndicator />
}
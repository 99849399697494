export const orderStates = {
  SUBMITTED: 1,
  CONFIRMED: 2,
  DECLINED: 3,
  PACKED: 4,
  COMPLETED: 5
}

export const orderStateInfo = {
  [orderStates.SUBMITTED]: {
    label: "Bestellung wurde aufgegeben",
    shortLabel: "Bestellung aufgegeben",
    completed: false,
    storeLabel: "Erhalten",
    storeStepNr: 1
  },
  [orderStates.CONFIRMED]: {
    label: "Bestellung von Bäckerei bestätigt",
    shortLabel: "Bestellung bestätigt",
    completed: false,
    storeLabel: "Angenommen",
    storeStepNr: 2
  },
  [orderStates.DECLINED]: {
    label: "Bestellung von Bäckerei abgelehnt",
    shortLabel: "Bestellung abgelehnt",
    completed: true,
    storeLabel: "Abgelehnt",
    storeStepNr: 2
  },
  [orderStates.PACKED]: {
    label: "Bestellung in der Filiale fertig gepackt",
    shortLabel: "Bestellung fertig gepackt",
    completed: false,
    storeLabel: "Gepackt",
    storeStepNr: 3
  },
  [orderStates.COMPLETED]: {
    label: "Bestellung abgeschlossen",
    shortLabel: "Bestellung abgeschlossen",
    completed: true,
    storeLabel: "Abgeholt",
    storeStepNr: 4
  },
}

// Get next action from current orderState
export const orderStateActionMapping = {
  [orderStates.CONFIRMED]: {
    actionLabel: "Als gepackt markieren",
    nextOrderState: orderStates.PACKED
  },
  [orderStates.PACKED]: {
    actionLabel: "Als abgeholt markieren",
    nextOrderState: orderStates.COMPLETED
  }
}
import { Duration } from "luxon";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setOpenModal } from "../../actions/general";
import { groupSetStoreOpeningHoursRequested } from "../../actions/store";
import { useGroupActionStoreContext } from "../../helpers/contexts";
import { ModalType } from "../../helpers/modals";
import { isValidTime, pluralize } from "../../utils/datetime";
import { DefaultButton } from "../buttons/DefaultButton";
import { OpeningHoursInput } from "../inputs/OpeningHoursInput";
import { GroupActionTabs } from "./GroupActionTabs";

export const GroupOpeningHours = ({ match, history }) => {
  const [newOpeningHours, setNewOpeningHours] = useState(undefined);
  const { selectedStoreIds } = useGroupActionStoreContext();
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(setOpenModal(ModalType.GroupActionConfirm, {
      selectedStoreIds,
      title: "Diese Öffnungszeiten auf folgende Filialen übertragen?",
      onConfirm: (callback) => {
        dispatch(groupSetStoreOpeningHoursRequested(selectedStoreIds, newOpeningHours, callback));
      }
    }));
  }

  const verifyInputs = () => {
    if (!newOpeningHours) return false;

    let validOpeningHours = true;
    newOpeningHours.forEach(h => {
      if (!validOpeningHours) return;
      if (!isValidTime(h.openTime) || !isValidTime(h.closeTime)) {
        validOpeningHours = false;
      } else {
        const openTime = Duration.fromISOTime(h.openTime).toMillis();
        const closeTime = Duration.fromISOTime(h.closeTime).toMillis();
        const valid = openTime < closeTime; // Also returns false if invalid time
        if (!valid) validOpeningHours = false;
      }
    });
    return validOpeningHours;
  }
  const validInputs = verifyInputs();

  return (
    <div className="groupActionWrapper">
      <GroupActionTabs history={history} />

      <div className="groupActionContentWrapper">
        <span className="large title">Öffnungszeiten der Filialen</span>
        <OpeningHoursInput data={newOpeningHours} onChange={(d) => setNewOpeningHours(d)} hideTitle={true} />
        <div className="actionButtons">
          {selectedStoreIds.length === 0 && <span className="noneSelectedInfo">Bitte wählen Sie mindestens eine Filiale aus</span>}
          {selectedStoreIds.length > 0 && !validInputs && <span className="noneSelectedInfo">Bitte füllen Sie alle erforderlichen Zeiten korrekt aus</span>}
          {selectedStoreIds.length > 0 && validInputs && <DefaultButton text={`Auf ${selectedStoreIds.length} ${pluralize("Filiale", "n", selectedStoreIds.length)} anwenden`} onPress={() => submit()} />}
        </div>
      </div>
    </div>
  )
}
const initialState = {
  stores: null
};

export const store = (state = initialState, action) => {
  console.log(action.type)
  switch (action.type) {
    case "EDIT_STORE_SUCCEEDED": {
      const newStores = [ ...state.stores ];
      const index = state.stores.findIndex(s => s.id === action.storeId);
      if (index === -1) return state;
      const { name, phone, openingHours, disabled } = action.storeData;
      newStores[index] = {
        ...newStores[index],
        name, phone, openingHours, disabled
      }
      return {
        ...state,
        stores: newStores
      }
    }
    case "SET_STORE_IMAGE_SUCCEEDED": {
      const newStores = [ ...state.stores ];
      const index = state.stores.findIndex(s => s.id === action.storeId);
      if (index === -1) return state;
      newStores[index] = {
        ...newStores[index],
        imageUrl: action.url
      }
      return {
        ...state,
        stores: newStores
      }
    }
    case "DELETE_STORE_SUCCEEDED": {
      if (!state.stores) return state;
      const newStores = state.stores.filter(s => s.id !== action.storeId);
      return {
        ...state,
        stores: newStores
      }
    }
    case "ADD_STORE_SUCCEEDED": {
      return {
        ...state,
        stores: [
          ...state.stores,
          action.storeData
        ]
      }
    }
    case "RETRIEVE_STORE_LIST_SUCCEEDED": {
      return {
        ...state,
        stores: action.storeList
      }
    }
    default:
      return state;
  }
};

import { useGroupActionStoreContext } from "../../../helpers/contexts";
import { pluralize } from "../../../utils/datetime";

export const SelectedStoresCounter = () => {
    const { selectedStoreIds } = useGroupActionStoreContext();
    const amount = selectedStoreIds ? selectedStoreIds.length : 0;
    const colorClassName = amount > 0 ? "success" : "error";
    return (
        <div>
            <span className={`medium ${colorClassName}`}>{`${amount} ${pluralize("Filiale", "n", amount)} ausgewählt`}</span>
        </div>
    )
}
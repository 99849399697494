import { useGroupActionStoreContext } from "../../../helpers/contexts";
import { CheckBox2 } from "../../inputs/CheckBox"

export const SelectAllStoresToggle = ({ stores }) => {
    const { selectedStoreIds, setSelectedStoreIds } = useGroupActionStoreContext();

    const handleToggle = () => {
        if (!stores) return;
        if (selectedStoreIds.length === stores.length) {
            setSelectedStoreIds([]);
        } else {
            setSelectedStoreIds(stores.map((s) => s.id));
        }
    }
    const everythingSelected = stores && selectedStoreIds.length === stores.length;

    const getToggleText = () => {
        const amount = stores ? stores.length : 0;
        return everythingSelected ? `Alle ${amount} Filialen abwählen` : `Alle ${amount} Filialen auswählen`;
    }

    return (
        <div className={`groupActionListElement ${false ? 'selected' : ''}`} onClick={handleToggle}>
            <div className="checkContainer">
                <CheckBox2 value={everythingSelected} />
            </div>
            <div className="elemInfoContainer">
                <span className="medium">{getToggleText()}</span>
            </div>
            <div className="selectedIndicator" />
        </div>
    )
}
export const companyLoginRequested = (username, password, companyId, handleResult) => ({
  type: "COMPANY_LOGIN_REQUESTED",
  username, password, companyId, handleResult
});

export const companyLoginSucceeded = (token) => ({
  type: "COMPANY_LOGIN_SUCCEEDED",
  token
});

export const logout = () => ({
  type: "LOGOUT"
});

export const autoLoginStoreRequested = (storeId, handleResponse) => ({
  type: "AUTO_LOGIN_STORE_REQUESTED",
  storeId, handleResponse
});
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveProductListRequested } from "../../actions/product";
import { GroupProductEntry } from "./subcomponents/GroupProductEntry";
import { GroupActionTabs } from "./GroupActionTabs";
import { DefaultButton } from "../buttons/DefaultButton";
import { useGroupActionStoreContext } from "../../helpers/contexts";
import { pluralize } from "../../utils/datetime";
import { setOpenModal } from "../../actions/general";
import { ModalType } from "../../helpers/modals";
import { groupModifyStoreProductsRequested } from "../../actions/store";
import { surprisePackageCategoryId } from "../../data/categoryData";

const ModificationOperation = {
  ADD: 1,
  REMOVE: 2
}

export const GroupProductCategory = {
  ALL: 1,
  REGULAR: 2,
  SURPRISE_PACKAGE: 3
}

export const GroupProducts = ({ match, history }) => {
  const products = useSelector(state => state.product.products);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [allowedCategory, setAllowedCategory] = useState(GroupProductCategory.ALL);
  const { selectedStoreIds } = useGroupActionStoreContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveProductListRequested());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedProductIds.length === 0) {
      setAllowedCategory(GroupProductCategory.ALL);
    } else {
      const hasSurprisePackageProducts = selectedProductIds.find((pId) => {
        const product = products.find((p) => p.id === pId);
        return product.categoryId === surprisePackageCategoryId
      });
      setAllowedCategory(hasSurprisePackageProducts ? GroupProductCategory.SURPRISE_PACKAGE : GroupProductCategory.REGULAR);
    }
  }, [selectedProductIds, products]);

  const handleOnToggleProduct = (productId) => {
    if (selectedProductIds.indexOf(productId) === -1) {
      const newSelectedProductIds = [...selectedProductIds];
      newSelectedProductIds.push(productId);
      setSelectedProductIds(newSelectedProductIds);
    } else {
      const index = selectedProductIds.indexOf(productId);
      if (index !== -1) {
        const newSelectedProductIds = [...selectedProductIds];
        newSelectedProductIds.splice(index, 1);
        setSelectedProductIds(newSelectedProductIds)
      }
    }
  }

  const checkBeforeSubmit = (operation) => {
    if (operation === ModificationOperation.ADD) {
      const hasSurprisePackageProducts = selectedProductIds.find((pId) => {
        const product = products.find((p) => p.id === pId);
        return product.categoryId === surprisePackageCategoryId
      });

      if (hasSurprisePackageProducts) {
        dispatch(setOpenModal(ModalType.GroupActionDailyLimit, {
          title: "Produkte der Kategorie Überraschungstüte",
          onConfirm: (dailyAmountAvailable, callback) => {
            callback();
            submitModifyStores(operation, dailyAmountAvailable);
          }
        }));
        return;
      }
    }
    submitModifyStores(operation);
  }

  const submitModifyStores = (operation, dailyAmountAvailable) => {
    dispatch(setOpenModal(ModalType.GroupActionConfirm, {
      selectedStoreIds,
      title: "Diese Änderungen am Sortiment auf folgende Filialen übertragen?",
      onConfirm: (callback) => {
        dispatch(groupModifyStoreProductsRequested(selectedStoreIds, selectedProductIds, operation, dailyAmountAvailable, callback));
      }
    }));
  }

  const getDisabledState = (productCategoryId) => {
    switch (allowedCategory) {
      case GroupProductCategory.ALL: {
        return false;
      }
      case GroupProductCategory.REGULAR: {
        return productCategoryId === surprisePackageCategoryId;
      }
      case GroupProductCategory.SURPRISE_PACKAGE: {
        return productCategoryId !== surprisePackageCategoryId;
      }
      default: {
        return false;
      }
    }
  }

  const productList = products && products.map(p =>
    <GroupProductEntry
      key={p.id} {...p}
      selected={selectedProductIds.indexOf(p.id) !== -1}
      onToggle={handleOnToggleProduct}
      disabled={getDisabledState(p.categoryId)}
    />);

  return (
    <div className="groupActionWrapper">
      <GroupActionTabs history={history} />
      <div className="groupActionContentWrapper">
        <span className="large title">Produktsortiment</span>

        <div className="productList">
          {productList}
        </div>

        <div className="actionButtons">
          {selectedStoreIds.length === 0 && <span className="noneSelectedInfo">Bitte wählen Sie mindestens eine Filiale aus</span>}
          {selectedStoreIds.length > 0 && selectedProductIds.length === 0 && <span className="noneSelectedInfo">Bitte wählen Sie mindestens ein Produkt aus</span>}
          {selectedStoreIds.length > 0 && selectedProductIds.length > 0 && (
            <React.Fragment>
              <DefaultButton text={`In ${selectedStoreIds.length} ${pluralize("Filiale", "n", selectedStoreIds.length)} entfernen`} onPress={() => checkBeforeSubmit(ModificationOperation.REMOVE)} />
              <DefaultButton text={`In ${selectedStoreIds.length} ${pluralize("Filiale", "n", selectedStoreIds.length)} hinzufügen`} onPress={() => checkBeforeSubmit(ModificationOperation.ADD)} />
            </React.Fragment>
          )}
        </div>
      </div>
    </div >
  )
}
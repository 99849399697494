const initialState = {
  products: null,
  categories: []
};

export const product = (state = initialState, action) => {
  switch (action.type) {
    case "EDIT_PRODUCT_SUCCEEDED": {
      const newProducts = [ ...state.products ];
      const index = state.products.findIndex(s => s.id === action.productId);
      if (index === -1) return state;
      newProducts[index] = {
        ...newProducts[index],
        ...action.productData
      }
      return {
        ...state,
        products: newProducts
      }
    }
    case "SET_PRODUCT_IMAGE_SUCCEEDED": {
      const newProducts = [ ...state.products ];
      const index = state.products.findIndex(s => s.id === action.productId);
      if (index === -1) return state;
      newProducts[index] = {
        ...newProducts[index],
        imageUrl: action.url
      }
      return {
        ...state,
        products: newProducts
      }
    }
    case "DELETE_PRODUCT_SUCCEEDED": {
      if (!state.products) return state;
      const newProducts = state.products.filter(s => s.id !== action.productId);
      return {
        ...state,
        products: newProducts
      }
    }
    case "ADD_PRODUCT_SUCCEEDED": {
      return {
        ...state,
        products: [
          ...state.products,
          action.productData
        ]
      }
    }
    case "RETRIEVE_PRODUCT_LIST_SUCCEEDED": {
      return {
        ...state,
        products: action.productList
      }
    }
    case "GET_CATEGORIES_SUCCEEDED": {
      return {
        ...state,
        categories: action.categoryList
      }
    }
    default:
      return state;
  }
};

import React from "react";
import { OrderTab } from "./OrderTab";

export const OrderTabs = ({ openOrderAmount, packedOrderAmount }) => {
  return (
    <div className="orderTabs">
      <OrderTab id={1} icon="clock" label="Offen" amountIndicator={openOrderAmount} amountIndicatorColor='var(--warning)' />
      <OrderTab id={2} icon="shopping-bag" label="Gepackt" amountIndicator={packedOrderAmount} />
      <OrderTab id={3} icon="check" label="Fertig" />
    </div>
  )
}
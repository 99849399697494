import React from "react";
import { convertToCurrency } from "../../utils/conversion";

export const OrderProductEntry = ({ name, price, amount }) => {
  return (
    <div className="orderProductEntry">
      <span>{`${amount} x ${name}`}<span style={{ color: 'var(--onSurfaceLight)'}}> ({convertToCurrency(price)})</span></span>
      <span>+ {convertToCurrency(amount * price)}</span>
    </div>
  )
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { Feather as Icon } from 'react-web-vector-icons';
import { retrieveProductListRequested } from "../actions/product";
import { IconButton } from "../components/buttons/IconButton";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import { AddProduct } from "../components/product/AddProduct";
import { EditProduct } from "../components/product/EditProduct";
import { ProductDetails } from "../components/product/ProductDetails";
import { ProductEntry } from "../components/product/ProductEntry";
import "../style/routes/Product.scss";

export const Products = ({ history }) => {
  const products = useSelector(state => state.product.products);
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveProductListRequested());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredProducts = (searchString === "") ? products : products && products.filter(p => p.name.toLowerCase().includes(searchString.toLowerCase()));
  const productList = filteredProducts && filteredProducts.map(p => <ProductEntry key={p.id} {...p} />);

  return (
    <div className="appContent">
      <div className="pageHeader">
        <div className="pageHeaderLeft">
          <span className="pageTitle">Produktverwaltung</span>
          <span className="pageDescription">Ermöglicht das Anlegen und Bearbeiten von Produkten</span>
        </div>
        <div className="pageHeaderRight">
          <IconButton icon="plus" text="Produkt hinzufügen" onPress={() => history.push("/products/add")} />
        </div>
      </div>
      <div className="pageContent">
        <div className="card maxHeight leftList">
          <div className="searchHeader">
            <Icon name="search" color='var(--onSurfaceStrong)' size={20} />
            <input type="text" placeholder="Nach Bezeichnung filtern" value={searchString} onChange={e => setSearchString(e.target.value)} />
          </div>
          <div className="listContent hasSearchHeader">
            {products !== null ? productList : <ActivityIndicator />}
            {filteredProducts && filteredProducts.length === 0 && <span className="emptyText">Keine Produkte gefunden.</span>}
          </div>
        </div>
        <div className="card maxHeight">
          <Route exact path="/products" render={() => <span className="emptyText">Bitte wähle ein Produkt aus</span>} />
          <Route exact path={["/products/add", "/products/added"]} component={AddProduct} />
          <Route exact path="/product/:selectedProductId" component={ProductDetails} />
          <Route exact path="/product/edit/:selectedProductId" component={EditProduct} />
        </div>
      </div>
    </div>
  )
}
import React from "react";
import { convertToCurrency } from "../../utils/conversion";

export const OrderSummaryItem = ({ name, imageUrl, measure, price, amount }) => {
  return (
    <div className="item">
      <div className="itemImage">
        <img src={imageUrl} alt="Produktbild" />
      </div>
      <div className="itemInfos">
        <span className="medium">{name}</span>
        <span>{measure} ({convertToCurrency(price)})</span>
      </div>
      <div className="itemAmount">
        <span className="bold large">{amount}</span>
        <span>Stück</span>
      </div>
    </div>
  )
}
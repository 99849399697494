import { put, takeEvery, select, call } from "redux-saga/effects";
import { retrieveOpenOrdersSucceeded, retrievePackedOrdersSucceeded, retrieveDoneOrdersSucceeded, getOrderDetailsSucceeded, getStoresWithOrdersSucceeded, getOrderOverviewSucceeded } from "../actions/order";
import { authAPIRequest } from "../api/requests";
import { genericHandleResult } from "./sagaHelper";
import { addGlobalMessageRequested } from "../actions/general";
import { notificationTypes } from "../data/notificatonTypes";
import { statusCodes } from "../api/config";

export function* watchAllOrderActions() {
  yield takeEvery("RETRIEVE_OPEN_ORDERS_REQUESTED", beginRetrieveOpenOrderList);
  yield takeEvery("RETRIEVE_PACKED_ORDERS_REQUESTED", beginRetrievePackedOrderList);
  yield takeEvery("RETRIEVE_DONE_ORDERS_REQUESTED", beginRetrieveDoneOrderList);
  yield takeEvery("GET_ORDER_DETAILS_REQUESTED", beginGetOrderDetails);
  yield takeEvery("UPDATE_ORDER_STATE_REQUESTED", beginUpdateOrderState);
  yield takeEvery("GET_STORES_WITH_ORDERS_REQUESTED", beginGetStoresWithOrders);
  yield takeEvery("GET_ORDER_OVERVIEW_REQUESTED", beginGetOrderOverview);
  yield takeEvery('GET_ORDER_INVOICE_REQUESTED', beginGetOrderInvoice);
}

function* beginRetrieveOpenOrderList() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-open-orders", {}, token));

    yield genericHandleResult(
      request.statusCode,
      retrieveOpenOrdersSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginRetrievePackedOrderList() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-packed-orders", {}, token));

    yield genericHandleResult(
      request.statusCode,
      retrievePackedOrdersSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginRetrieveDoneOrderList(action) {
  try {
    const { lastId, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-completed-orders", { lastId }, token));

    if (request.result && request.result.length > 0) handleResponse(request.result.length, request.result[request.result.length - 1].id);
    else handleResponse(0, null);

    yield genericHandleResult(
      request.statusCode,
      retrieveDoneOrdersSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGetOrderDetails(action) {
  try {
    const { orderId } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-order-details", { orderId }, token));

    yield genericHandleResult(
      request.statusCode,
      getOrderDetailsSucceeded(orderId, request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginUpdateOrderState(action) {
  try {
    const { orderId, newOrderState, reason, setLoading } = action;
    setLoading(true);

    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/update-order-status", { orderId, status: newOrderState, reason: reason ? reason : null }, token));

    setLoading(false);

    yield genericHandleResult(
      request.statusCode,
      null, // updateOrderStateSucceeded(orderId, request.result ? request.result.status : null),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGetStoresWithOrders(action) {
  try {
    const { date } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-stores-with-orders", { date }, token));

    yield genericHandleResult(
      request.statusCode,
      getStoresWithOrdersSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGetOrderOverview(action) {
  try {
    const { storeId, date, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-order-overview", { date, storeId }, token));
    handleResponse();

    yield genericHandleResult(
      request.statusCode,
      getOrderOverviewSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGetOrderInvoice(action) {
  const { orderId, handleResponse } = action;
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/request-order-invoice", { orderId }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS && request.result ? request.result.invoiceUrl : null);

    yield genericHandleResult(
      request.statusCode,
      null,
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    handleResponse(null);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}
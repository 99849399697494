import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductRequested } from "../../actions/product";
import { taxOptions } from "../../data/productData";
import { convertToCurrency } from "../../utils/conversion";
import { DefaultButton } from "../buttons/DefaultButton";
import { DetailActionButton } from "../buttons/DetailActionButton";
import { ActivityIndicator } from "../loading/ActivityIndicator";
import { VariableDisplay } from "../text/VariableDisplay";
import { NutritionTable } from "./NutritionTable";
import { DateTime } from 'luxon';

export const ProductDetails = ({ match, history }) => {
  const selectedProductId = parseInt(match.params.selectedProductId);
  const product = useSelector(state => state.product.products ? state.product.products.filter(p => p.id === selectedProductId)[0] : null);
  const categories = useSelector(state => state.product.categories);
  const [deletePressed, setDeletePressed] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();

  const deleteProduct = () => {
    setDeleteLoading(true);
    dispatch(deleteProductRequested(selectedProductId, handleDeleteResponse));
  }

  const handleDeleteResponse = (success) => {
    setDeleteLoading(false);
    if (success) history.push("/products");
  }

  useEffect(() => {
    if (deletePressed) setDeletePressed(false); // Don't include deletePressed in deps -> would cause incorrect behaviour
  }, [selectedProductId]); // eslint-disable-line react-hooks/exhaustive-deps

  const productCategory = product && categories.find(c => c.id === product.categoryId);
  const dataDayNames = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  const viewDayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
  const availableDayString = product && product.availability && dataDayNames.reduce((a, d, i) => {
    if (product.availability[d]) {
      if (a !== "") return a + ", " + viewDayNames[i];
      return a + viewDayNames[i];
    } else {
      return a;
    }
  }, "");

  const specialAvailableDayString = product && product.specialAvailability && product.specialAvailability.reduce((a, d) => {
    const prefix = a === "" ? "" : ", ";
    if (d.startDate === d.endDate) {
      const sDate = DateTime.fromFormat(d.startDate, "yyyy-LL-dd").toFormat("dd.LL.yyyy");
      return a + prefix + sDate;
    } else {
      const sDate = DateTime.fromFormat(d.startDate, "yyyy-LL-dd").toFormat("dd.LL.yyyy");
      const eDate = DateTime.fromFormat(d.endDate, "yyyy-LL-dd").toFormat("dd.LL.yyyy");
      return a + prefix + `${sDate} - ${eDate}`;
    }
  }, "");

  return product ? (
    <React.Fragment>
      <div className="detailHeader">
        <div className="detailHeaderTitle">
          <span className="big">Produktdetails</span>
        </div>
        <div className="detailHeaderActions">
          <DetailActionButton icon="edit" onPress={() => history.push("/product/edit/" + selectedProductId)} />
          <DetailActionButton icon="trash-2" onPress={() => setDeletePressed(true)} />
        </div>
      </div>
      <div className="detailContent">
        {deletePressed && <div className="insideModal">
          <span className="medium">Sind Sie sicher, dass Sie dieses Produkt unwiderruflich löschen möchten?</span>
          <div className="insideModalActions">
            <DefaultButton text="Löschen" loading={deleteLoading} isDelete={true} onPress={() => deleteProduct()} />
            {!deleteLoading && <DefaultButton text="Abbrechen" isFlat={true} onPress={() => setDeletePressed(false)} />}
          </div>
        </div>}
        <div className="product">
          <div className="productInfoContainer">
            <VariableDisplay label="Name" value={product.name} marginBottom={true} />
            <VariableDisplay label="Gewicht/Anzahl" value={product.measure} marginBottom={true} />
            <VariableDisplay label="Preis" value={convertToCurrency(parseFloat(product.price))} marginBottom={true} />
            <VariableDisplay label="Steuersatz" value={taxOptions.find(t => t.id === product.taxId).name} marginBottom={true} />
            <VariableDisplay label="Kategorie" value={productCategory ? productCategory.name : "-"} marginBottom={true} />
            <VariableDisplay label="Beschreibung" value={product.description} marginBottom={true} boldValueText={false} />
            <VariableDisplay label="Inhaltsstoffe" value={product.ingredients} marginBottom={true} boldValueText={false} />
            <VariableDisplay label="Allergiehinweise" value={product.allergyAdvice} marginBottom={true} boldValueText={false} />
            <VariableDisplay label="Verfügbarkeit" value={product.specialAvailability && product.specialAvailability.length > 0 ? specialAvailableDayString : availableDayString} marginBottom={true} boldValueText={false} />
            {product.nutritionFacts && !!product.nutritionFacts.enabled && <NutritionTable editMode={false} nutritionFacts={product.nutritionFacts} />}
          </div>
          <div className="productImageContainer">
            {product.imageUrl ? <img src={product.imageUrl} alt="Produktbild" /> : <div className="imgEmpty" />}
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : <ActivityIndicator />
}
const initialState = {
  maintenanceMode: false,
  notifications: [],
  openModal: null,
  modalParams: {}
};

export const general = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MAINTENANCE_MODE": {
      return {
        ...state,
        maintenanceMode: action.value
      }
    }
    case "REMOVE_GLOBAL_MESSAGE": {
      const newNotifications = state.notifications.filter(n => n.id !== action.id);
      return {
        ...state,
        notifications: newNotifications
      }
    }
    case "ADD_GLOBAL_MESSAGE": {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          action.message
        ]
      }
    }
    case "SET_OPEN_MODAL": {
      return {
        ...state,
        openModal: action.value,
        modalParams: action.params ? action.params : {}
      }
    }
    default:
      return state;
  }
};

import { all, put } from 'redux-saga/effects';
import { setMaintenanceMode } from '../actions/general';
import { statusCodes } from "./../api/config";

export function* genericHandleResult(statusCode, success, error, onSuccess) {
  switch (statusCode) {
    case statusCodes.SUCCESS: {
      if (success) yield put(success);
      if (onSuccess) {
        yield all(onSuccess.map(e => put(e)))
      };
      break;
    }
    case statusCodes.TOKEN_ERROR: {
      yield put({ type: "LOGOUT" });
      break;
    }
    case statusCodes.AUTH_ERROR: {
      if (error) yield put(error);
      break;
    }
    case statusCodes.INVALID_PARAMS: {
      if (error) yield put(error);
      break;
    }
    case statusCodes.UNKNOWN_ERROR: {
      if (error) yield put(error);
      break;
    }
    case statusCodes.OUTDATED_CLIENT_VERSION: {
      if (error) yield put(error);
      break;
    }
    case statusCodes.ONGOING_MAINTENANCE: {
      yield put(setMaintenanceMode(true));
      break;
    }
    case statusCodes.NOT_FOUND: {
      if (error) yield put(error);
      break;
    }
    case statusCodes.INTERNAL_SERVER_ERROR: {
      if (error) yield put(error);
      break;
    }
    default: {
      if (error) yield put(error);
      break;
    }
  }
};
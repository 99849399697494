import React, { useEffect, useState } from "react";
import { Feather as Icon } from 'react-web-vector-icons';
import { DateTime } from 'luxon';

export const MonthDateSwitch = ({ onChange }) => {
  const [startDate, setStartDate] = useState(DateTime.now().startOf('month'));
  const [endDate, setEndDate] = useState(DateTime.now().endOf('month'));

  useEffect(() => {
    if (onChange) onChange({ startDate: startDate.toISODate(), endDate: endDate.toISODate() });
  }, [startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDisplayDateString = () => {
    return startDate.toFormat("LLL yyyy");
  }

  const goForward = () => {
    setStartDate(startDate.plus({ month: 1 }));
    setEndDate(endDate.plus({ month: 1 }));
  }

  const goBackward = () => {
    setStartDate(startDate.minus({ month: 1 }));
    setEndDate(endDate.minus({ month: 1 }));
  }

  return (
    <div className="basicDateSwitch">
      <div className="backward" onClick={() => goBackward()}>
        <Icon name="chevron-left" color='var(--onSurfaceStrong)' size={24} />
      </div>
      <div className="display">
        <span>{getDisplayDateString()}</span>
      </div>
      <div className="forward" onClick={() => goForward()}>
        <Icon name="chevron-right" color='var(--onSurfaceStrong)' size={24} />
      </div>
    </div>
  )
}
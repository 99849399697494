import React from "react";
import { VariableDisplay } from "../components/text/VariableDisplay";
import "./../style/routes/Support.scss";

export const Support = () => {
  return (
    <div className="appContent">
      <div className="pageHeader">
        <div className="pageHeaderLeft">
          <span className="pageTitle">Support / Hilfe</span>
          <span className="pageDescription">Wir helfen Ihnen gerne weiter</span>
        </div>
      </div>
      <div className="pageContent">
        <div className="card maxHeight">
          <div className="support">
            <span>Sollten Sie Fragen zur Nutzung oder sonstige Probleme mit der Anwendung haben, kontaktieren Sie uns gerne durch folgende Kommunikationswege.</span>
            <div className="contactOptions">
              <VariableDisplay label="E-Mail" value="kontakt@brotbox.app" />
              <VariableDisplay label="Telefon" value="+49 151 2566 3526" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActivityIndicator } from "../loading/ActivityIndicator";
import Chart from 'react-apexcharts'
import { getDetailedRevenueRequested } from "../../actions/statistic";
import { getSingleDayFromDate } from "../../utils/datetime";
import { convertToCurrencyDouble } from "../../utils/conversion";

export const StatisticRevenueDetails = ({ timeSpan }) => {
  const colors = useSelector(state => state.company.colors);
  const revenueData = useSelector(state => state.statistic.revenueData);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'apexchart-example',
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 400,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: false,
        }
      }
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.85,
        opacityTo: 0
      }
    },
    stroke: {
      curve: 'smooth',
    },
    colors: [colors.primary],
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: null // [...]
    }
  });
  const [areaDataSet, setAreaDataSet] = useState(null);
  const [barDataSet, setBarDataSet] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!timeSpan.startDate) return;
    setLoading(true);
    dispatch(getDetailedRevenueRequested(timeSpan));
  }, [timeSpan]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (revenueData) {
      const endDay = parseInt(getSingleDayFromDate(timeSpan.endDate));
      const dayObj = {};
      [...Array(endDay)].forEach((_, d) => {
        const rd = d + 1;
        dayObj[rd] = {};
        dayObj[rd].day = rd;
      });
      revenueData.forEach(rd => dayObj[getSingleDayFromDate(rd.date)].sum = rd.sum);
      const xaxisData = [...Array(endDay)].map((_, d) => d + 1 );

      setChartOptions({ ...chartOptions, xaxis: { categories: xaxisData } });
      let sum = 0;
      setAreaDataSet([{
        name: "Umsatz in EUR",
        data: Object.keys(dayObj).map(elem => convertToCurrencyDouble(sum = sum + (dayObj[elem].sum || 0)))
      }]);
      setBarDataSet([{
        name: "Umsatz in EUR",
        data: Object.keys(dayObj).map(r => convertToCurrencyDouble(dayObj[r].sum || 0))
      }]);
      setLoading(false);
    }
  }, [revenueData]); // eslint-disable-line react-hooks/exhaustive-deps

  return revenueData && areaDataSet && barDataSet && !loading ? (
    <React.Fragment>
      <div className="detailHeader" style={{ borderBottomWidth: 0 }}>
        <div className="detailHeaderTitle">
          <span className="big">Übersicht zum Umsatz</span>
        </div>
        <div className="detailHeaderActions">
          {/* <DetailActionButton icon="printer" onPress={() => null} /> */}
        </div>
      </div>
      <div className="detailContent" style={{ overflowX: 'hidden' }}>
        <div className="statistics">
          <Chart options={chartOptions} series={areaDataSet} type="area" height={380} />
          <Chart options={chartOptions} series={barDataSet} type="bar" height={380} />
        </div>
      </div>
    </React.Fragment>
  ) : <ActivityIndicator />
}
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { checkMaintenanceModeStatus } from "../actions/general";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";

export const Maintenance = () => {
  const maintenanceMode = useSelector(state => state.general.maintenanceMode);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const checkInterval = setInterval(() => {
      dispatch(checkMaintenanceModeStatus());
    }, 10000);
    return () => clearInterval(checkInterval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!maintenanceMode) history.push("/stores");
  }, [maintenanceMode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="maintenance">
      <ActivityIndicator />
      <span className="extraLarge medium">Wartungsarbeiten</span>
      <span>Derzeit werden wichtige Wartungsarbeiten ausgeführt. Bitte versuche es in 10-20 min erneut.</span>
    </div>
  )
}
import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/authentication";
import { SidemenuHeader } from "./SidemenuHeader";
import "./../../style/components/Sidemenu.scss";
import { SidemenuEntry } from "./SidemenuEntry";

export const Sidemenu = ({ sidemenuCollapsed, setSidemenuCollapsed, isTablet }) => {
  const dispatch = useDispatch();

  const doLogout = () => {
    dispatch(logout());
  }

  return (
    <div className="sidemenu">
      <SidemenuHeader sidemenuCollapsed={sidemenuCollapsed} setSidemenuCollapsed={setSidemenuCollapsed} />
      <div className="entryListTop">
        <SidemenuEntry icon="home" label="Filialen" activePathnames={["stores", "store"]} navRoute="/stores" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
        <SidemenuEntry icon="package" label="Produkte" activePathnames={["products", "product"]} navRoute="/products" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
        <SidemenuEntry icon="shopping-bag" label="Bestellungen" activePathnames={["orders", "order"]} navRoute="/orders" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
        <SidemenuEntry icon="clipboard" label="Produktion" activePathnames={["order-summaries", "order-summary"]} navRoute="/order-summaries" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
        <SidemenuEntry icon="layers" label="Gruppenaktionen" activePathnames={["group-actions", "group-actions/order-settings", "group-actions/special-days", "group-actions/products"]} navRoute="/group-actions" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
        <SidemenuEntry icon="bar-chart-2" label="Statistiken" activePathnames={["stats", "stat"]} navRoute="/stats" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
      </div>
      <div className="entryListBottom">
        <SidemenuEntry icon="help-circle" label="Hilfe" activePathnames={["support"]} navRoute="/support" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
        <SidemenuEntry icon="settings" label="Einstellungen" activePathnames={["settings"]} navRoute="/settings" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
        <SidemenuEntry icon="log-out" label="Abmelden" onPress={doLogout} />
      </div>
    </div>
  )
}
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addStoreRequested, getStoreCoordinatesRequested, setStoreImageRequested } from "../../actions/store";
import { DefaultButton } from "../buttons/DefaultButton";
import { DefaultInput } from "../inputs/DefaultInput";
import { Feather as Icon } from 'react-web-vector-icons';
import { Route } from "react-router-dom";
import { CheckBox } from "../inputs/CheckBox";

export const AddStore = ({ history, location }) => {
  const [storeValues, setStoreValues] = useState({ name: "", phone: "" });
  const [storeAddress, setStoreAddress] = useState({ street: "", streetNumber: "", postalCode: "", city: "" });
  const [username, setUsername] = useState("");
  const updateStoreValue = (name, val) => setStoreValues({ ...storeValues, [name]: val });
  const updateStoreAddressValue = (name, val) => setStoreAddress({ ...storeAddress, [name]: val });
  const [storeDisabled, setStoreDisabled] = useState(true);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileRef = useRef();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [createdStore, setCreatedStore] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedFile) {
      setImagePreview(null)
      return
    }
    setErrorMsg(null);
    const objectUrl = URL.createObjectURL(selectedFile)
    setImagePreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  useEffect(() => {
    if (location.pathname.split('/')[2] !== 'added') {
      setCreatedStore(false);
      setCredentials(null);
    }
  }, [location]);

  const addStore = () => {
    if (!selectedFile) {
      setErrorMsg("Bitte wähle zunächst ein Bild für diese Filiale aus.");
      return;
    }

    setLoading(true);
    setErrorMsg(null);
    const { street, streetNumber, postalCode, city } = storeAddress;
    dispatch(getStoreCoordinatesRequested(`${street} ${streetNumber} ${postalCode} ${city}`, handleCoordinateResponse));
  }

  const handleCoordinateResponse = (foundRelevantResult, coordinates) => {
    if (foundRelevantResult) {
      dispatch(addStoreRequested({ ...storeValues, user: { username }, disabled: storeDisabled }, { ...storeAddress, ...coordinates }, handleResponse));
    } else {
      setLoading(false);
      setErrorMsg("Kein relevantes Suchergebnis für die angegebene Adresse gefunden. Bitte überarbeite die angegebene Adresse.");
    }
  }

  const handleResponse = (success, request) => {
    if (success) {
      if (request.result) {
        setCredentials({ username: request.result.user.username, password: request.result.user.password })
        setCreatedStore(true);
        history.push("/stores/added");
        dispatch(setStoreImageRequested(request.result.id, selectedFile, () => {
          setLoading(false);
          resetEverything();
        }));
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrorMsg(request.errorMsg);
    }
  }

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const resetEverything = () => {
    setStoreValues({ name: "", phone: "" });
    setStoreAddress({ street: "", streetNumber: "", postalCode: "", city: "" });
    setUsername("");
    setSelectedFile(null);
    setImagePreview(null);
    setLoading(false);
    setErrorMsg(null);
  }

  return (
    <React.Fragment>
      <Route exact path="/stores/add" render={() => (
        <React.Fragment>
          <div className="detailHeader">
            <span className="big">Neue Filiale anlegen</span>
          </div>
          <div className="detailContent">
            <div className="addStore">
              <div className="storeInfoContainer">
                <DefaultInput name="Name" type="text" placeholder="Name der Filiale" value={storeValues["name"]} onChange={e => updateStoreValue("name", e.target.value)} />
                <div className="streetSplitter">
                  <DefaultInput name="Straße" type="text" placeholder="Musterstraße" value={storeAddress["street"]} onChange={e => updateStoreAddressValue("street", e.target.value)} />
                  <DefaultInput name="Hausnr." type="text" placeholder="12" value={storeAddress["streetNumber"]} onChange={e => updateStoreAddressValue("streetNumber", e.target.value)} />
                </div>
                <DefaultInput name="Postleitzahl" type="number" placeholder="12345" value={storeAddress["postalCode"]} onChange={e => updateStoreAddressValue("postalCode", e.target.value)} />
                <DefaultInput name="Stadt" type="text" placeholder="Musterstadt" value={storeAddress["city"]} onChange={e => updateStoreAddressValue("city", e.target.value)} />
                <DefaultInput name="Telefon" type="text" placeholder="+49..." value={storeValues["phone"]} onChange={e => updateStoreValue("phone", e.target.value)} />
                <DefaultInput name="Nutzername für Anmeldung" type="text" placeholder="z.B. filiale-brackenheim" value={username} onChange={e => setUsername(e.target.value)} />

                <div className="storeEnabledSwitchContainer">
                  <CheckBox value={storeDisabled} inverted={true} onChange={(v) => setStoreDisabled(v)} />
                  <span onClick={() => setStoreDisabled(!storeDisabled)}>Filiale {storeDisabled && <u>nicht</u>} sofort öffentlich anzeigen</span>
                </div>
              </div>
              <div className="storeImageContainer">
                <input ref={fileRef} type="file" name="file" onChange={onSelectFile} accept="image/x-png,image/jpeg" />
                <div onClick={() => fileRef.current.click()}>
                  {selectedFile && imagePreview ?
                    <img src={imagePreview} alt="Bild der Filiale" /> :
                    <div className="imagePlaceholder" >
                      <span>Bild auswählen</span>
                    </div>}
                </div>
              </div>
            </div>
            <div className="addStoreBottom">
              <span className="infoText">Hinweis: Weitere Details wie z.B. die Öffnungszeiten können nach dem Anlegen festgelegt werden. Ein sicheres Passwort für die Anmeldung wird automatisch generiert und Ihnen nach dem Anlegen angezeigt.</span>
              {errorMsg ? <span className="error">{errorMsg}</span> : null}
              <DefaultButton text="Filiale erstellen" left={true} loading={loading} onPress={() => addStore()} />
            </div>
          </div>
        </React.Fragment>
      )} />
      <Route exact path="/stores/added" render={() => createdStore ? (
        <div className="detailContent">
          <div className="addStoreSucceeded">
            <Icon name="check" color='var(--success)' size={48} />
            <span className="successMsg big medium">Filiale erfolgreich angelegt!</span>
            <span>Bitte notieren Sie sich die folgenden Zugangsdaten. Das generierte Passwort wird hier einmalig angezeigt und kann anschließend nicht erneut eingesehen werden.</span>
            {credentials && <div className="credentialContainer">
              <DefaultInput name="Nutzername" type="text" value={credentials.username} disabled={true} />
              <DefaultInput name="Passwort" type="text" value={credentials.password} disabled={true} />
            </div>}
          </div>
        </div>
      ) : null} />
    </React.Fragment>
  )
}
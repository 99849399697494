const GAPaths = {
    default: "/group-actions",
    orderTimeSettings: "/group-actions/order-settings",
    specialDays: "/group-actions/special-days",
    products: "/group-actions/products"
}

export const GroupActionTabs = ({ history }) => {
    return (
        <div className="groupActionTabs">
            <div className="tab" onClick={() => history.push(GAPaths.default)}>
                <span className={history.location.pathname === GAPaths.default ? "bold" : ""}>Öffnungszeiten</span>
            </div>
            <div className="tab" onClick={() => history.push(GAPaths.orderTimeSettings)}>
                <span className={history.location.pathname === GAPaths.orderTimeSettings ? "bold" : ""}>Bestellzeiten</span>
            </div>
            <div className="tab" onClick={() => history.push(GAPaths.specialDays)}>
                <span className={history.location.pathname === GAPaths.specialDays ? "bold" : ""}>Sonderöffnungszeiten</span>
            </div>
            <div className="tab" onClick={() => history.push(GAPaths.products)}>
                <span className={history.location.pathname === GAPaths.products ? "bold" : ""}>Sortiment</span>
            </div>
        </div>
    )
}
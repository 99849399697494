import React from "react";

export const OrderStateIndicator = ({ stepNr, label, isFirst, isCompleted }) => {
  return (
    <div className={`orderStateIndicator${isCompleted ? ' completed' : ''}`}>
      <div className="circle">
        <span>{stepNr}</span>
        {!isFirst && <div className="line" />}
      </div>
      <span>{label}</span>
    </div>
  )
}
import React from "react";
import { useHistory } from "react-router-dom";
import { orderStates } from "./../../data/orderStates";
import { OrderStateDisplay } from "./OrderStateDisplay";
import { OrderUntilTime } from "./OrderUntilTime";

export const OrderEntry = ({ id, selectedOrderId, currentOrderState, executionTime, pickupCode }) => {
  const history = useHistory();
  const isSelected = parseInt(selectedOrderId) === id;

  const clickOrderEvent = () => {
    history.push("/order/" + id);
  }

  return (
    <div className={`orderEntry${isSelected ? ' selected' : ''}`} onClick={() => clickOrderEvent()}>
      <div className="orderLeft">
        <span className="bold">Bestellung #{id}</span>
        <OrderStateDisplay executionTime={executionTime} currentOrderState={currentOrderState} />
        <span>Abholung <OrderUntilTime executionTime={executionTime} /></span>
      </div>
      <div className="orderRight">
        {currentOrderState !== orderStates.SUBMITTED && <div className="smallPickupCode">
          <span className="bold">{pickupCode}</span>
        </div>}
      </div>
      <div className="selectedIndicator" />
    </div>
  )
}
import { put, takeEvery, call, select } from "redux-saga/effects";
import { getCompanySettingsSucceeded, setCompanyLogoSucceeded, setSurprisePackageSettingsSucceeded } from "../actions/company";
import { addGlobalMessageRequested } from "../actions/general";
import { statusCodes } from "../api/config";
import { authAPIFormDataRequest, authAPIRequest, basicAPIRequest } from "../api/requests";
import { notificationTypes } from "../data/notificatonTypes";
import { genericHandleResult } from "./sagaHelper";

export function* watchAllCompanyActions() {
  yield takeEvery("COMPANY_LOOKUP_REQUESTED", beginCompanyLookup);
  yield takeEvery("GET_COMPANY_SETTINGS_REQUESTED", beginGetCompanySettings);
  yield takeEvery("SET_COMPANY_LOGO_REQUESTED", beginSetCompanyLogo);
  yield takeEvery("SET_SURPRISE_PACKAGE_SETTINGS_REQUESTED", beginSetSurprisePackageSettings);
}

function* beginCompanyLookup(action) {
  try {
    const { urlIdentifier, handleResponse } = action;
    const request = yield call(() => basicAPIRequest("/company/company-lookup", { urlCode: urlIdentifier }));
    handleResponse({ exists: request.statusCode === statusCodes.SUCCESS, result: request.result, errorMsg: request.errorMsg });
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }))
  }
}

function* beginGetCompanySettings(action) {
  try {
    const { handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-company-settings", { }, token));
    handleResponse();

    yield genericHandleResult(
      request.statusCode,
      getCompanySettingsSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginSetCompanyLogo(action) {
  try {
    const { imageData, handleResponse } = action;
    const token = yield select(state => state.authentication.token);

    const formData = new FormData();
    formData.append('file', imageData);
    const request = yield call(() => authAPIFormDataRequest("/company/set-company-logo", formData, token));

    handleResponse(request.statusCode === statusCodes.SUCCESS);
    yield genericHandleResult(
      request.statusCode,
      setCompanyLogoSucceeded(request.result ? request.result.url : null),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginSetSurprisePackageSettings(action) {
  try {
    const { surprisePackageSettings, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/update-company-settings", { surprisePackageSettings }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      setSurprisePackageSettingsSucceeded(request.result),
      null
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}
import openSocket from "socket.io-client";
import { addGlobalMessageRequested } from "./actions/general";
import { insertNewOrder, updateOrderStateSucceeded } from "./actions/order";
import { notificationTypes } from "./data/notificatonTypes";

export const listenForSocketEvents = (token, dispatch) => {
  const socket = openSocket(process.env.REACT_APP_SOCKET_URL);

  socket.on("connect", () => {
    // dispatch(setOnlineStatus(true));
    console.log("connneeectted")
    socket.emit("joinCompanyRequest", { token });
  });

  socket.on("joinCompanyFailed", error => {
    console.log(error.errorMsg)
    dispatch(addGlobalMessageRequested({ text: error.errorMsg, type: notificationTypes.ERROR }))
  });

  socket.on("disconnect", () => {
    // dispatch(setOnlineStatus(false));
  });

  /* ORDER */
  socket.on("newOrder", (orderData) => {
    const { id, pickupCode, executionTime, currentOrderState } = orderData;
    dispatch(insertNewOrder({ id, pickupCode, executionTime, currentOrderState: parseInt(currentOrderState) }));
  });

  socket.on("updateOrderStatus", (orderData) => {
    const { orderId, status, reason } = orderData;
    dispatch(updateOrderStateSucceeded(orderId, status, reason));
  });
}
import { put, takeEvery, call, delay } from 'redux-saga/effects';
import { addGlobalMessage, addGlobalMessageRequested, clearGlobalMessages, removeGlobalMessage, setMaintenanceMode } from '../actions/general';
import { statusCodes } from '../api/config';
import { basicAPIRequest } from '../api/requests';
import { notificationTypes } from '../data/notificatonTypes';

export function* watchAllGeneralActions() {
  yield takeEvery('CHECK_MAINTENANCE_MODE_STATUS', beginCheckMaintenanceModeStatus);
  yield takeEvery('ADD_GLOBAL_MESSAGE_REQUESTED', beginAddGlobalMessage)
}

function* beginCheckMaintenanceModeStatus() {
  try {
    const request = yield call(() => basicAPIRequest("/general/check-status", { }));
    if (request.statusCode !== statusCodes.ONGOING_MAINTENANCE) yield put(setMaintenanceMode(false));
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.WARNING, actions: [{ text: "Schließen", action: clearGlobalMessages() }]}));
  }
}

function* beginAddGlobalMessage(action) {
  const { message } = action;
  const id = Math.random().toString(36).substr(2, 12);
  yield put(addGlobalMessage({ id, ...message }));

  if (message.duration) {
    yield delay(message.duration);
    yield put(removeGlobalMessage(id));
  }
}
import React, { useEffect, useReducer } from "react";
import { orderStateInfo, orderStates } from "../../data/orderStates";
import { isInPast } from "./../../utils/datetime";

export const OrderStateDisplay = ({ executionTime, currentOrderState, reason }) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      forceUpdate();
    }, 5000);
    return () => clearInterval(refreshInterval);
  }, []);

  const isOverdue = isInPast(executionTime);

  return (
    <span>Status: {orderStateInfo[currentOrderState].completed ? (
      currentOrderState === orderStates.COMPLETED ? <span className="success">Abgeschlossen</span> :
        <>
          <span className="error">Abgelehnt</span>
          {reason && <span>{` (${reason})`}</span>}
        </>
    ) : (
      isOverdue ? <span className="error">Offen - Überfällig</span> : <span className="warning">Offen</span>
    )}</span>
  )
}
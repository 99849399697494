import React from "react";
import { useHistory } from "react-router-dom";
import { Feather as Icon } from 'react-web-vector-icons';

export const StoreEntry = ({ id, imageUrl, disabled, address }) => {
  const { street, streetNumber, postalCode, city } = address;
  const history = useHistory();

  const selectStore = () => {
    history.push("/store/" + id);
  }

  return (
    <div className="defaultListElement" onClick={() => selectStore()}>
      <div className="elemImageContainer">
        {imageUrl ? <img src={imageUrl} alt="Bild der Filiale" /> : <div className="imgEmpty" />}
      </div>
      <div className="elemInfoContainer">
        <span className="medium">{`${street} ${streetNumber}`}</span>
        <span>{`${postalCode} ${city}`}</span>
        {disabled ? <div className="disabledContainer">
          <Icon name={"alert-circle"} color={"var(--error)"} size={16} />
          <span className="error">Nicht gelistet</span>
        </div> : null}
      </div>
    </div>
  )
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { Feather as Icon } from 'react-web-vector-icons';
import { getStoresWithOrdersRequested } from "../actions/order";
import { BasicDateSwitch } from "../components/inputs/BasicDateSwitch";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import { OrderSummaryAllEntry } from "../components/ordersummary/OrderSummaryAllEntry";
import { OrderSummaryDetails } from "../components/ordersummary/OrderSummaryDetails";
import { OrderSummaryStoreEntry } from "../components/ordersummary/OrderSummaryStoreEntry";
import "../style/routes/OrderSummary.scss";

export const OrderSummary = ({ match }) => {
  const selectedStoreId = match.params.id;
  const summaryStores = useSelector(state => state.order.summaryStores);
  const [searchString, setSearchString] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedDate) dispatch(getStoresWithOrdersRequested(selectedDate));
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredStores = (searchString === "") ? summaryStores : summaryStores && summaryStores.filter(p => p.name.toLowerCase().includes(searchString.toLowerCase()));
  const storeList = filteredStores && filteredStores.map(p => <OrderSummaryStoreEntry key={p.id} {...p} selectedStoreId={selectedStoreId} />);

  return (
    <div className="appContent">
      <div className="pageHeader">
        <div className="pageHeaderLeft">
          <span className="pageTitle">Produktionsplanung</span>
          <span className="pageDescription">Zeigt eine Zusammenfassung aller Bestellungen an.</span>
        </div>
        <div className="pageHeaderRight">
          <BasicDateSwitch onChange={(d) => setSelectedDate(d)} />
        </div>
      </div>
      <div className="pageContent">
        <div className="card maxHeight leftList">
          <div className="searchHeader">
            <Icon name="search" color='var(--onSurfaceStrong)' size={20} />
            <input type="text" placeholder="Nach Name filtern" value={searchString} onChange={e => setSearchString(e.target.value)} />
          </div>
          <div className="listContent hasSearchHeader">
            <OrderSummaryAllEntry selectedStoreId={selectedStoreId} />
            {summaryStores !== null ? storeList : <ActivityIndicator />}
            {/* {filteredStores && filteredStores.length === 0 && <span className="emptyText">Keine Filialen gefunden.</span>} */}
          </div>
        </div>
        <div className="card maxHeight">
          <Route exact path="/order-summaries" render={() => <span className="emptyText">Bitte wähle eine Filiale aus</span>} />
          <Route exact path="/order-summary/:selectedStoreId" render={(props) => <OrderSummaryDetails {...props} date={selectedDate} />} />
        </div>
      </div>
    </div>
  )
}
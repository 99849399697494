import { put, takeEvery, call, select } from "redux-saga/effects";
import { addGlobalMessageRequested } from "../actions/general";
import { addProductSucceeded, deleteProductSucceeded, editProductSucceeded, getCategoriesSucceeded, retrieveProductListSucceeded, setProductImageSucceeded } from "../actions/product";
import { statusCodes } from "../api/config";
import { authAPIFormDataRequest, authAPIRequest } from "../api/requests";
import { notificationTypes } from "../data/notificatonTypes";
import { genericHandleResult } from "./sagaHelper";

export function* watchAllProductActions() {
  yield takeEvery("GET_CATEGORIES_REQUESTED", beginGetCategories);
  yield takeEvery("RETRIEVE_PRODUCT_LIST_REQUESTED", beginRetrieveProductList);
  yield takeEvery("ADD_PRODUCT_REQUESTED", beginAddProduct);
  yield takeEvery("SET_PRODUCT_IMAGE_REQUESTED", beginSetProductImage);
  yield takeEvery("DELETE_PRODUCT_REQUESTED", beginDeleteProduct);
  yield takeEvery("EDIT_PRODUCT_REQUESTED", beginEditProduct);
}

function* beginGetCategories() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-product-categories", { }, token));

    yield genericHandleResult(
      request.statusCode,
      getCategoriesSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginRetrieveProductList() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-products", {}, token));
    // const request = { statusCode: 6 }

    yield genericHandleResult(
      request.statusCode,
      retrieveProductListSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginAddProduct(action) {
  try {
    const { productData, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/create-product", productData, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS, request);

    yield genericHandleResult(
      request.statusCode,
      addProductSucceeded(request.result ? request.result : null),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginSetProductImage(action) {
  try {
    const { productId, imageData, handleResponse } = action;
    const token = yield select(state => state.authentication.token);

    const formData = new FormData();
    formData.append('file', imageData);
    formData.append('productId', productId);
    const request = yield call(() => authAPIFormDataRequest("/company/set-product-image", formData, token));

    handleResponse();
    yield genericHandleResult(
      request.statusCode,
      setProductImageSucceeded(productId, request.result ? request.result.url : null),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginDeleteProduct(action) {
  try {
    const { productId, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/delete-product", { productId }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS);

    yield genericHandleResult(
      request.statusCode,
      deleteProductSucceeded(productId),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginEditProduct(action) {
  try {
    const { productId, productData, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/edit-product", { productId, ...productData }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      editProductSucceeded(productId, productData),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}
import React from "react";
import { Redirect } from "react-router-dom";
import CacheRoute from 'react-router-cache-route'
import { useSelector } from "react-redux";

const ProtectedRoute = ({ path, location, ...rest }) => {
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const urlIdentifier = useSelector(state => state.company.urlIdentifier);
  return (
    <CacheRoute path={path}
      render={props =>
        loggedIn === true ? (
          <rest.component />
        ) : (
            <Redirect
              to={{
                pathname: "/login/" + urlIdentifier,
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

export default ProtectedRoute;

const initialState = {
  loggedIn: false,
  token: null,
};

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case "COMPANY_LOGIN_SUCCEEDED": {
      return {
        ...state,
        loggedIn: true,
        token: action.token
      }
    }
    default:
      return state;
  }
};

import React from "react";
import { Feather as Icon } from 'react-web-vector-icons';

export const CheckBox = ({ value, onChange, inverted = false }) => {
  const enabledIcon = inverted ? "x-circle" : "check-circle";
  const disabledIcon = inverted ? "check-circle" : "x-circle";
  return (
    <div className="checkBox" onClick={() => onChange ?  onChange(!value) : null}>
      <Icon name={value ? enabledIcon : disabledIcon} color='var(--onSurfaceStrong)' size={20} />
    </div>
  )
}

export const CheckBox2 = ({ value, onChange }) => {
  return (
    <div className="checkBox" onClick={() => onChange ? onChange(!value) : null}>
      <Icon name={value ? 'check-square' : 'square'} color='var(--onSurfaceStrong)' size={24} />
    </div>
  )
}
import { all } from 'redux-saga/effects';
import { watchAllAuthenticationActions } from './authenticationSaga';
import { watchAllCompanyActions } from './companySaga';
import { watchAllGeneralActions } from './generalSaga';
import { watchAllOrderActions } from './orderSaga';
import { watchAllProductActions } from './productSaga';
import { watchAllStatisticActions } from './statisticSaga';
import { watchAllStoreActions } from './storeSaga';

export default function* rootSaga() {
  yield all([
    watchAllAuthenticationActions(),
    watchAllCompanyActions(),
    watchAllStoreActions(),
    watchAllProductActions(),
    watchAllGeneralActions(),
    watchAllOrderActions(),
    watchAllStatisticActions()
  ])
}
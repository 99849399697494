const initialState = {
  id: 0,
  name: "",
  urlIdentifier: "",
  colors: {
    primary: "",
    onPrimary: ""
  },
  logoUrl: null,
  isWhitelabel: false,
  surprisePackageSettings: {
    pickupDurationMins: null, 
    bufferMins: null
  }
};

export const company = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COMPANY_LOGO_SUCCEEDED": {
      return {
        ...state,
        logoUrl: action.url
      }
    }
    case "GET_COMPANY_SETTINGS_SUCCEEDED": {
      const { logoUrl, isWhitelabel, primaryColor, onPrimaryColor, surprisePackageSettings } = action.companySettings;
      return {
        ...state,
        logoUrl,
        isWhitelabel: isWhitelabel ? true : false,
        colors: {
          primary: primaryColor,
          onPrimary: onPrimaryColor
        },
        surprisePackageSettings: surprisePackageSettings ?? { pickupDurationMins: "", bufferMins: "" }
      }
    }
    case "SET_COMPANY_INFOS": {
      const { id, name, colors, urlIdentifier, isWhitelabel } = action;
      return {
        ...state,
        id,
        name,
        colors,
        urlIdentifier,
        isWhitelabel: isWhitelabel ? true : false
      }
    }
    case "SET_SURPRISE_PACKAGE_SETTINGS_SUCCEEDED": {
      return {
        ...state,
        surprisePackageSettings: action.surprisePackageSettings
      }
    }
    default:
      return state;
  }
};

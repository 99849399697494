import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderOverviewRequested } from "../../actions/order";
import { getStandardGermanDate } from "../../utils/datetime";
import { DetailActionButton } from "../buttons/DetailActionButton";
import { ActivityIndicator } from "../loading/ActivityIndicator";
import { OrderSummaryItem } from "./OrderSummaryItem";

export const OrderSummaryDetails = ({ match, date }) => {
  const selectedStoreId = match.params.selectedStoreId;
  const summaryOrders = useSelector(state => state.order.summaryOrders);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (date && selectedStoreId) {
      setLoading(true);
      dispatch(getOrderOverviewRequested(selectedStoreId === "all" ? null : parseInt(selectedStoreId), date, () => {
        setLoading(false);
      }));
    }
  }, [selectedStoreId, date]); // eslint-disable-line react-hooks/exhaustive-deps

  const summaryOrderList = summaryOrders && summaryOrders.map(s => <OrderSummaryItem key={s.id} {...s} />)

  return summaryOrders && !loading ? (
    <React.Fragment>
      <div className="detailHeader">
        <div className="detailHeaderTitle">
          <span className="big">Gruppierte Produktliste</span><span className="big printOnly"> ({getStandardGermanDate(date)})</span>
        </div>
        <div className="detailHeaderActions">
          <DetailActionButton icon="printer" screenOnly={true} onPress={() => window.print()} />
        </div>
      </div>
      <div className="detailContent">
        <div className="orderSummary">
          {summaryOrderList}
          {summaryOrders && summaryOrders.length === 0 && <span className="emptyText">Keine Bestellungen gefunden.</span>}
        </div>
      </div>
    </React.Fragment>
  ) : <ActivityIndicator />
}
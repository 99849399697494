import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetOrderOverviewData } from "../../actions/order";

export const OrderSummaryStoreEntry = ({ id, selectedStoreId, name, imageUrl, street, streetNumber, postalCode, city }) => {
  const isSelected = parseInt(selectedStoreId) === id;
  const history = useHistory();
  const dispatch = useDispatch();

  const selectStore = () => {
    if (isSelected) return;
    dispatch(resetOrderOverviewData());
    history.push("/order-summary/" + id);
  }

  return (
    <div className={`defaultListElement${isSelected ? ' selected' : ''}`} onClick={() => selectStore()}>
      <div className="elemImageContainer">
        {imageUrl ? <img src={imageUrl} alt="Bild der Filiale" /> : <div className="imgEmpty" />}
      </div>
      <div className="elemInfoContainer">
        <span className="medium">{name}</span>
        <span>{`${street} ${streetNumber}`}</span>
        <span>{`${postalCode} ${city}`}</span>
      </div>
      <div className="selectedIndicator" />
    </div>
  )
}
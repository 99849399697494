import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setOpenModal } from "../../actions/general";
import { groupAddStoreSpecialDaysRequested } from "../../actions/store";
import { useGroupActionStoreContext } from "../../helpers/contexts";
import { ModalType } from "../../helpers/modals";
import { pluralize } from "../../utils/datetime";
import { DefaultButton } from "../buttons/DefaultButton";
import { ActivityIndicator } from "../loading/ActivityIndicator";
import { GroupActionTabs } from "./GroupActionTabs";
import { AddSpecialHours } from "./subcomponents/AddSpecialHours";
import { SpecialHoursEntry } from "./subcomponents/SpecialHoursEntry";

export const GroupSpecialDays = ({ match, history }) => {
  const { selectedStoreIds } = useGroupActionStoreContext();
  const dispatch = useDispatch();
  const [specialHours, setSpecialHours] = useState([]);

  const addSpecialHourEntry = (dataEntry) => {
    setSpecialHours([...specialHours, dataEntry]);
  }

  const removeSpecialHourEntry = (index) => {
    const newSpecialHours = [...specialHours];
    newSpecialHours.splice(index, 1);
    setSpecialHours(newSpecialHours);
  }

  const submit = () => {
    dispatch(setOpenModal(ModalType.GroupActionConfirm, {
       selectedStoreIds,
       title: "Diese Sonderöffnungszeiten auf folgende Filialen übertragen?",
       onConfirm: (callback) => {
        dispatch(groupAddStoreSpecialDaysRequested(selectedStoreIds, specialHours, callback));
       }
     }));
  }

  return (
    <div className="groupActionWrapper">
      <GroupActionTabs history={history} />
      <div className="groupActionContentWrapper">
        <span className="large title">Sonderfälle der Öffnungszeiten</span>

        <div className="specialHoursList">
          {specialHours && specialHours.length === 0 && <span className="emptyText">Noch keine Sonderfälle hinzugefügt.</span>}
          {specialHours ? specialHours.map((d, i) => <SpecialHoursEntry key={`sh${i}`} index={i} onRemove={removeSpecialHourEntry} {...d} />) : <ActivityIndicator />}
        </div>

        <span className="large title">Sonderfall hinzufügen</span>
        <AddSpecialHours onAdd={addSpecialHourEntry} />

        <div className="actionButtons">
        {selectedStoreIds.length === 0 && <span className="noneSelectedInfo">Bitte wählen Sie mindestens eine Filiale aus</span>}
        {selectedStoreIds.length > 0 && specialHours.length === 0 && <span className="noneSelectedInfo">Bitte legen Sie mindestens einen Sonderfall an</span>}
          {selectedStoreIds.length > 0 && specialHours.length > 0 &&  <DefaultButton text={`Auf ${selectedStoreIds.length} ${pluralize("Filiale", "n", selectedStoreIds.length)} anwenden`} onPress={() => submit()} />}
        </div>
      </div>
    </div>
  )
}


import React from "react";

export const OpeningHours = ({ data }) => {
  if (!data) return null;
  const weekdays = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
  return (
    <div className="openingHours">
      <span className="big medium">Öffnungszeiten</span>
      {data.map(d => <OpeningHour key={d.dayOfWeek} dayName={weekdays[d.dayOfWeek]} {...d} />)}
    </div>
  )
}

const OpeningHour = ({ dayName, openTime, closeTime, closed }) => {
  return (
    <div className="openingHour">
      <span>{dayName}:</span>
      <span>{closed ? "Geschlossen" : `${openTime} - ${closeTime}`}</span>
    </div>
  )
}
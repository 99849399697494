import React from "react";
import { Feather as Icon } from 'react-web-vector-icons';

export const DetailActionButton = ({ name, icon, onPress, screenOnly, tooltipText }) => {
  return (
    <div id={name} className={`detailActionButton ${screenOnly ? ' screenOnly' : ''}`} onClick={onPress}>
      <Icon name={icon} color='var(--onSurfaceStrong)' size={22} />
      {tooltipText && <span role="tooltip">{tooltipText}</span>}
    </div>
  )
}
import React, { useState } from "react";
import { DefaultInput } from "../inputs/DefaultInput";
import { SelectionInput } from "../inputs/SelectionInput";
import { orderAcceptanceModeData, orderAcceptanceModeDetails, orderAcceptanceModes } from "../../data/orderAcceptanceModes";
import { GroupActionTabs } from "./GroupActionTabs";
import { useGroupActionStoreContext } from "../../helpers/contexts";
import { DefaultButton } from "../buttons/DefaultButton";
import { isValidTime, pluralize } from "../../utils/datetime";
import { ModalType } from "../../helpers/modals";
import { useDispatch } from "react-redux";
import { setOpenModal } from "../../actions/general";
import { groupSetStoreOrderSettingsRequested } from "../../actions/store";

export const GroupOrderSettings = ({ match, history }) => {
  const [orderAcceptanceMode, setOrderAcceptanceMode] = useState(-1);
  const [minMinsBeforeOrder, setMinMinsBeforeOrder] = useState(30);
  const [untilTime, setUntilTime] = useState("18:00");
  const [daysBefore, setDaysBefore] = useState(1);
  const { selectedStoreIds } = useGroupActionStoreContext();
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(setOpenModal(ModalType.GroupActionConfirm, {
      selectedStoreIds,
      title: "Diese Bestelleinschränkungen auf folgende Filialen übertragen?",
      onConfirm: (callback) => {
        dispatch(groupSetStoreOrderSettingsRequested(selectedStoreIds, {
          orderAcceptanceMode,
          minMinsBeforeOrder: parseInt(minMinsBeforeOrder),
          untilTime,
          daysBefore: parseInt(daysBefore)
        }, callback));
      }
    }));
  }

  const verifyInputs = () => {
    if (orderAcceptanceMode === -1) return false;
    if (orderAcceptanceMode === orderAcceptanceModes.ALWAYS_PROVISIONALLY) {
      return minMinsBeforeOrder && !isNaN(minMinsBeforeOrder);
    } else {
      return minMinsBeforeOrder && !isNaN(minMinsBeforeOrder) && daysBefore && !isNaN(daysBefore) && isValidTime(untilTime);
    }
  }
  const validInputs = verifyInputs();

  return (
    <div className="groupActionWrapper">
      <GroupActionTabs history={history} />

      <div className="groupActionContentWrapper orderSettings">
        <span className="large title">Einschränkung der Bestellzeiten</span>
        <SelectionInput name="Variante der Bestellungsannahme" data={orderAcceptanceModeData} value={orderAcceptanceMode} onChange={e => setOrderAcceptanceMode(parseInt(e.target.value))} />
        {orderAcceptanceMode !== -1 && <span style={{ marginBottom: 16, display: 'inline-block' }}>{orderAcceptanceModeDetails[orderAcceptanceMode].desc}</span>}
        {orderAcceptanceMode !== 2 && <React.Fragment>
          <DefaultInput name="Tage zuvor" type="number" min={1} max={7} value={daysBefore} onChange={(e) => setDaysBefore(e.target.value)} />
          <DefaultInput name="Uhrzeit" value={untilTime} onChange={(e) => setUntilTime(e.target.value)} />
        </React.Fragment>}
        <DefaultInput name="Mindestzeit zwischen Bestellungsaufgabe und Abholung in Minuten (unabhängig)" min={0} type="number" value={minMinsBeforeOrder} onChange={(e) => setMinMinsBeforeOrder(e.target.value)} />

        <div className="actionButtons">
          {selectedStoreIds.length === 0 && <span className="noneSelectedInfo">Bitte wählen Sie mindestens eine Filiale aus</span>}
          {selectedStoreIds.length > 0 && !validInputs && <span className="noneSelectedInfo">Bitte füllen Sie alle erforderlichen Felder korrekt aus</span>}
          {selectedStoreIds.length > 0 && validInputs && <DefaultButton text={`Auf ${selectedStoreIds.length} ${pluralize("Filiale", "n", selectedStoreIds.length)} anwenden`} onPress={() => submit()} />}
        </div>
      </div>
    </div>
  )
}
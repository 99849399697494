import React from "react";
import { Feather as Icon } from 'react-web-vector-icons';
import { ActivityIndicator } from "../loading/ActivityIndicator";

export const IconButton = ({ icon, loading, text, onPress, left }) => {
  return (
    <div className={`iconButton${left ? ' left' : ''}`} onClick={() => onPress ? onPress() : null}>
      {loading ? <ActivityIndicator /> : (
        <React.Fragment>
          <Icon name={icon} color='var(--onPrimary)' size={20} />
          <span>{text}</span>
        </React.Fragment>
      )}
    </div>
  )
}
import React from "react";
import { useHistory } from "react-router-dom";
import { convertToCurrency } from "../../utils/conversion";

export const ProductEntry = ({ id, imageUrl, name, measure, price }) => {
  const history = useHistory();

  const selectProduct = () => {
    history.push("/product/" + id);
  }

  return (
    <div className="defaultListElement" onClick={() => selectProduct()}>
      <div className="elemImageContainer">
        {imageUrl ? <img src={imageUrl} alt="Produktbild" /> : <div className="imgEmpty" />}
      </div>
      <div className="elemInfoContainer">
        <span className="medium">{name}</span>
        <span>{measure ? `${measure} · ` : ''}{convertToCurrency(parseFloat(price))}</span>
      </div>
    </div>
  )
}
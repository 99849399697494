export const getStatisticsRequested = (timeSpan, handleResponse) => ({
  type: "GET_STATISTICS_REQUESTED",
  timeSpan, handleResponse
});

export const getStatisticsSucceeded = (stats) => ({
  type: "GET_STATISTICS_SUCCEEDED",
  stats
});

export const getDetailedRevenueRequested = (timeSpan) => ({
  type: "GET_DETAILED_REVENUE_REQUESTED",
  timeSpan
});

export const getDetailedRevenueSucceeded = (stats) => ({
  type: "GET_DETAILED_REVENUE_SUCCEEDED",
  stats
});

export const getDetailedOrdersRequested = (timeSpan) => ({
  type: "GET_DETAILED_ORDERS_REQUESTED",
  timeSpan
});

export const getDetailedOrdersSucceeded = (stats) => ({
  type: "GET_DETAILED_ORDERS_SUCCEEDED",
  stats
});

export const downloadOrderSummaryRequested = (timeSpan, fileFormat) => ({
  type: "DOWNLOAD_ORDER_SUMMARY_REQUESTED",
  timeSpan, fileFormat
});
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { getStatisticsRequested } from "../actions/statistic";
import { MonthDateSwitch } from "../components/inputs/MonthDateSwitch";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import { ListKPI } from "../components/statistic/ListKPI";
import { StatisticOrderDetails } from "../components/statistic/StatisticOrderDetails";
import { StatisticRevenueDetails } from "../components/statistic/StatisticRevenueDetails";
import "../style/routes/Statistics.scss";
import { convertToCurrency } from "../utils/conversion";

export const Statistics = () => {
  const { revenue, orderCount, productsSold } = useSelector(state => state.statistic.totals);
  const [timeSpan, setTimeSpan] = useState({ startDate: null, endDate: null });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!timeSpan.startDate) return;
    setLoading(true);
    dispatch(getStatisticsRequested(timeSpan, () => {
      setLoading(false);
    }));
  }, [timeSpan.startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="appContent">
      <div className="pageHeader">
        <div className="pageHeaderLeft">
          <span className="pageTitle">Statistiken</span>
          <span className="pageDescription">Bietet Einblicke in nützliche Statistiken zu den Bestellungen.</span>
        </div>
        <div className="pageHeaderRight">
          <MonthDateSwitch onChange={(ts) => setTimeSpan(ts)} />
        </div>
      </div>
      <div className="pageContent">
        <div className="card maxHeight leftList">
          <div className="listContent">
            {loading ? <ActivityIndicator /> : (
              <React.Fragment>
                <ListKPI icon="trending-up" name="Erzielter Umsatz" value={convertToCurrency(revenue)} showChevron={true} navRoute="/stat/revenue" />
                <ListKPI icon="clipboard" name="Erhaltene Bestellungen" value={orderCount} showChevron={true} navRoute="/stat/orders" />
                <ListKPI icon="package" name="Verkaufte Produkte" value={productsSold} />
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="card maxHeight">
          <Route exact path="/stats" render={() => <span className="emptyText">Bitte wähle eine Metrik aus</span>} />
          <Route exact path="/stat/revenue" render={(props) => <StatisticRevenueDetails {...props} timeSpan={timeSpan} />} />
          <Route exact path="/stat/orders" render={(props) => <StatisticOrderDetails {...props} timeSpan={timeSpan} />} />
        </div>
      </div>
    </div>
  )
}
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanySettingsRequested, setCompanyLogoRequested, setSurprisePackageSettingsRequested } from "../actions/company";
import { DefaultButton } from "../components/buttons/DefaultButton";
import { IconButton } from "../components/buttons/IconButton";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import "../style/routes/Settings.scss";
import { DefaultInput } from "../components/inputs/DefaultInput";

export const Settings = () => {
  const { logoUrl, isWhitelabel, surprisePackageSettings } = useSelector(state => state.company);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileRef = useRef();

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const [newSurprisePackageSettings, setNewSurprisePackageSettings] = useState({ pickupDurationMins: "", bufferMins: "" });
  const [surpriseSaveLoading, setSurpriseSaveLoading] = useState(false);
  const [surpriseSuccessMsg, setSurpriseSuccessMsg] = useState(null);
  const [surpriseErrorMsg, setSurpriseErrorMsg] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getCompanySettingsRequested(() => {
      setLoading(false);
    }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedFile) {
      setImagePreview(null)
      return
    }
    setErrorMsg(null);
    const objectUrl = URL.createObjectURL(selectedFile)
    setImagePreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  useEffect(() => {
    if (surprisePackageSettings && surprisePackageSettings.pickupDurationMins !== undefined && surprisePackageSettings.bufferMins !== undefined) setNewSurprisePackageSettings(surprisePackageSettings);
  }, [surprisePackageSettings]);

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const setCompanyLogo = () => {
    if (!selectedFile) {
      setErrorMsg("Bitte wähle zunächst ein Bild als Unternehmenslogo aus.");
      return;
    }

    setSaveLoading(true);
    setErrorMsg(null);
    dispatch(setCompanyLogoRequested(selectedFile, (success) => {
      setSaveLoading(false);
      if (success) {
        setSuccessMsg("Das Logo wurde erfolgreich gespeichert.");
        setTimeout(() => setSuccessMsg(null), 3000);

        setSelectedFile(null);
        setImagePreview(null);
      }
    }));
  }

  const dismissSelectedImage = () => {
    setSelectedFile(null);
    setImagePreview(null);
  }

  const saveSurprisePackageSettings = () => {
    setSurpriseSaveLoading(true);
    setSurpriseErrorMsg(null);
    dispatch(setSurprisePackageSettingsRequested(newSurprisePackageSettings, (success, errorMsg) => {
      setSurpriseSaveLoading(false);
      if (success) {
        setSurpriseSuccessMsg("Erfolgreich gespeichert.");
        setTimeout(() => setSuccessMsg(null), 3000);
      } else {
        setSurpriseErrorMsg(errorMsg);
      }
    }))
  }

  return (
    <div className="appContent">
      <div className="pageHeader">
        <div className="pageHeaderLeft">
          <span className="pageTitle">Einstellungen zum Unternehmen</span>
          <span className="pageDescription">Hier können Einstellungen zum gesamten Unternehmen konfiguriert werden.</span>
        </div>
        <div className="pageHeaderRight"></div>
      </div>
      <div className="pageContent settings">
        <div className="cardContainer leftView sideCard">
          {loading ? (
            <div className="card">
              <ActivityIndicator />
            </div>
          ) :
            <React.Fragment>
              {!isWhitelabel && <div className="card defaultPadding topCard fitHeight dynamicSize">
                <span className="large">Unternehmenslogo</span>
                <div className="companyImageContainer">
                  <span className="infoText">Bitte wählen Sie ein Logo im Format 1:1 aus:</span>
                  <input ref={fileRef} type="file" name="file" onChange={onSelectFile} accept="image/x-png,image/jpeg" />
                  {logoUrl || imagePreview ? (
                    <div className="editImageContainer" onClick={() => fileRef.current.click()}>
                      <img src={imagePreview ? imagePreview : logoUrl} alt="Bild der Filiale" />
                      <span className="big">Bild ändern</span>
                    </div>
                  ) : (
                    <div className="imagePlaceholder" onClick={() => fileRef.current.click()}>
                      <span>Bild auswählen</span>
                    </div>
                  )}
                  {errorMsg ? <span className="error">{errorMsg}</span> : null}
                  {successMsg ? <span className="success">{successMsg}</span> : null}
                  {imagePreview ? (
                    <div className="selectionButtons">
                      <DefaultButton text="Speichern" left={true} loading={saveLoading} onPress={() => setCompanyLogo()} />
                      <DefaultButton text="Abbrechen" left={true} isFlat={true} onPress={() => dismissSelectedImage()} />
                    </div>
                  ) :
                    <IconButton icon="edit" text="Logo ändern" left={true} onPress={() => fileRef.current.click()} />}
                </div>
              </div>}
              <div className="card defaultPadding fitHeight dynamicSize">
                <span className="large title">Überraschungstüten</span>
                <DefaultInput
                  name="Beginn der Abholzeit vor Ladenschluss (in Minuten)"
                  type="number"
                  placeholder="z.B. 30"
                  disabled={surpriseSaveLoading}
                  value={newSurprisePackageSettings.pickupDurationMins}
                  onChange={(e) => setNewSurprisePackageSettings({ ...newSurprisePackageSettings, pickupDurationMins: parseInt(e.target.value) })}
                  style={{ width: "96px" }}
                />
                <DefaultInput
                  name="Bestellschluss vor Beginn der Abholzeit (in Minuten)"
                  type="number"
                  placeholder="z.B. 30"
                  disabled={surpriseSaveLoading}
                  value={newSurprisePackageSettings.bufferMins}
                  onChange={(e) => setNewSurprisePackageSettings({ ...newSurprisePackageSettings, bufferMins: parseInt(e.target.value) })}
                  style={{ width: "96px" }} />
                {surpriseErrorMsg ? <span className="error">{surpriseErrorMsg}</span> : null}
                {surpriseSuccessMsg ? <span className="success">{surpriseSuccessMsg}</span> : null}
                <div>
                  <DefaultButton text="Speichern" left={true} loading={surpriseSaveLoading} onPress={() => saveSurprisePackageSettings()} />
                </div>
              </div>
            </React.Fragment>
          }
          {/* <div className="card defaultPadding" style={{ visibility: 'hidden' }}>
            <span className="large">-</span>
          </div> */}
        </div>
        {/* <div className="card maxHeight defaultPadding" style={{ visibility: 'hidden' }}>
          <span className="large">-</span>
        </div> */}
      </div>
    </div>
  )
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadOrderSummaryRequested, getDetailedOrdersRequested } from "../../actions/statistic";
import { DropdownButton } from "../buttons/DropdownButton";
import { ActivityIndicator } from "../loading/ActivityIndicator";
// import { DetailActionButton } from "../buttons/DetailActionButton";
import Chart from 'react-apexcharts'
import { getSingleDayFromDate } from "../../utils/datetime";
// import { convertToCurrencyDouble } from "../../utils/conversion";

export const StatisticOrderDetails = ({ timeSpan }) => {
  const colors = useSelector(state => state.company.colors);
  const orderData = useSelector(state => state.statistic.orderData);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'apexchart-example',
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 400,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: false,
        }
      }
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.85,
        opacityTo: 0
      }
    },
    stroke: {
      curve: 'smooth',
    },
    colors: [colors.primary],
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: null // [...]
    }
  });
  const [areaDataSet, setAreaDataSet] = useState(null);
  const [barDataSet, setBarDataSet] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!timeSpan.startDate) return;
    setLoading(true);
    dispatch(getDetailedOrdersRequested(timeSpan));
  }, [timeSpan]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orderData) {
      const endDay = parseInt(getSingleDayFromDate(timeSpan.endDate));
      const dayObj = {};
      [...Array(endDay)].forEach((_, d) => {
        const rd = d + 1;
        dayObj[rd] = {};
        dayObj[rd].day = rd;
      });
      orderData.forEach(rd => dayObj[getSingleDayFromDate(rd.date)].amount = rd.amount);
      const xaxisData = [...Array(endDay)].map((_, d) => d + 1 );

      setChartOptions({ ...chartOptions, xaxis: { categories: xaxisData } });
      let sum = 0;
      setAreaDataSet([{
        name: "Anzahl an Bestellungen",
        data: Object.keys(dayObj).map(elem => (sum = sum + (dayObj[elem].amount || 0)))
      }]);
      setBarDataSet([{
        name: "Anzahl an Bestellungen",
        data: Object.keys(dayObj).map(r => (dayObj[r].amount || 0))
      }]);
      setLoading(false);
    }
  }, [orderData]); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadAsCSV = () => {
    console.log("CSV Download");
    dispatch(downloadOrderSummaryRequested(timeSpan, "csv"));
  }

  // const downloadAsPDF = () => {
  //   console.log("PDF Download");
  // }

  return orderData && areaDataSet && barDataSet && !loading ? (
    <React.Fragment>
      <div className="detailHeader">
        <div className="detailHeaderTitle">
          <span className="big">Übersicht zu Bestellungen</span>
        </div>
        <div className="detailHeaderActions">
          <DropdownButton
            text="Herunterladen"
            icon="download-cloud"
            loading={false}
            isFlat={true}
            options={[{
              name: "CSV-Datei",
              onPress: () => downloadAsCSV()
            }]}
          />
          {/* <DetailActionButton icon="download-cloud" onPress={() => null} /> */}
          {/* <DetailActionButton icon="printer" onPress={() => null} /> */}
        </div>
      </div>
      <div className="detailContent" style={{ overflowX: 'hidden' }}>
        <div className="statistics">
          <Chart options={chartOptions} series={areaDataSet} type="area" height={380} />
          <Chart options={chartOptions} series={barDataSet} type="bar" height={380} />
        </div>
      </div>
    </React.Fragment>
  ) : <ActivityIndicator />
}
export const retrieveStoreListRequested = () => ({
  type: "RETRIEVE_STORE_LIST_REQUESTED"
});

export const retrieveStoreListSucceeded = (storeList) => ({
  type: "RETRIEVE_STORE_LIST_SUCCEEDED",
  storeList
});

export const getStoreCoordinatesRequested = (address, handleResponse) => ({
  type: "GET_STORE_COORDINATES_REQUESTED",
  address, handleResponse
});

export const addStoreRequested = (storeInfos, address, handleResponse) => ({
  type: "ADD_STORE_REQUESTED",
  storeInfos, address, handleResponse
});

export const addStoreSucceeded = (storeData) => ({
  type: "ADD_STORE_SUCCEEDED",
  storeData
});

export const setStoreImageRequested = (storeId, imageData, handleResponse) => ({
  type: "SET_STORE_IMAGE_REQUESTED",
  storeId, imageData, handleResponse
});

export const setStoreImageSucceeded  = (storeId, url) => ({
  type: "SET_STORE_IMAGE_SUCCEEDED",
  storeId, url
});

export const deleteStoreRequested = (storeId, handleResponse) => ({
  type: "DELETE_STORE_REQUESTED",
  storeId, handleResponse
});

export const deleteStoreSucceeded = (storeId) => ({
  type: "DELETE_STORE_SUCCEEDED",
  storeId
});

export const editStoreRequested = (storeId, storeData, handleResponse) => ({
  type: "EDIT_STORE_REQUESTED",
  storeId, storeData, handleResponse
});

export const editStoreSucceeded = (storeId, storeData) => ({
  type: "EDIT_STORE_SUCCEEDED",
  storeId, storeData
});

/* GROUP ACTIONS */

export const groupSetStoreOpeningHoursRequested = (storeIds, openingHours, handleResponse) => ({
  type: "GROUP_SET_STORE_OPENING_HOURS_REQUESTED",
  storeIds, openingHours, handleResponse
});

export const groupSetStoreOrderSettingsRequested = (storeIds, orderTimeSettings, handleResponse) => ({
  type: "GROUP_SET_STORE_ORDER_SETTINGS_REQUESTED",
  storeIds, orderTimeSettings, handleResponse
});

export const groupAddStoreSpecialDaysRequested = (storeIds, specialDays, handleResponse) => ({
  type: "GROUP_ADD_STORE_SPECIAL_DAYS_REQUESTED",
  storeIds, specialDays, handleResponse
});

export const groupModifyStoreProductsRequested = (storeIds, productIds, operation, dailyAmountAvailable, handleResponse) => ({
  type: "GROUP_MODIFY_STORE_PRODUCTS_REQUESTED",
  storeIds, productIds, operation, dailyAmountAvailable, handleResponse
});
import React from "react";
import { useSelector } from "react-redux";
import { Feather as Icon } from 'react-web-vector-icons';

export const SidemenuHeader = ({ sidemenuCollapsed, setSidemenuCollapsed }) => {
  const name = useSelector(state => state.company.name);
  return (
    <div className="sidemenuHeader">
      <span className="title">{name}</span>
      <div className="clickable" onClick={() => setSidemenuCollapsed(!sidemenuCollapsed)}><Icon name='menu' color='var(--onPrimary)' size={24} /></div>
    </div>
  )
}
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const IncapacitationWatcher = () => {
  const maintenanceMode = useSelector(state => state.general.maintenanceMode);
  const history = useHistory();

  useEffect(() => {
    if (maintenanceMode) history.push("/maintenance");
  }, [maintenanceMode]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
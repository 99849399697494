import React from "react";
import { surprisePackageCategoryId } from "../../../data/categoryData";
import { convertToCurrency } from "../../../utils/conversion";
import { CheckBox2 } from "../../inputs/CheckBox";
import { Feather as Icon } from 'react-web-vector-icons';

export const GroupProductEntry = ({ id, imageUrl, name, measure, price, categoryId, selected, onToggle, disabled }) => {
  const isSurprisePackageProduct = categoryId === surprisePackageCategoryId;
  return (
    <div className={`productEntry ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`} onClick={() => disabled ? null : onToggle(id)}>
      <div className="checkContainer">
        <CheckBox2 value={selected} />
      </div>
      <div className="elemImageContainer">
        {imageUrl ? <img src={imageUrl} alt="Produktbild" /> : <div className="imgEmpty" />}
      </div>
      <div className="elemInfoContainer">
        <span className="medium">{name}</span>
        <span>{measure ? `${measure} · ` : ''}{convertToCurrency(parseFloat(price))}
        {isSurprisePackageProduct && <span> · <span className="badge icon"><Icon name="gift" color='var(--onPrimary)' size={18} /></span></span>}</span>
      </div>
      <div className="selectedIndicator" />
    </div>
  )
}
import React, { useContext } from "react";
import { Feather as Icon } from 'react-web-vector-icons';
import { OrderTabContext } from "../../helpers/contexts";

export const OrderTab = ({ id, icon, label, amountIndicator, amountIndicatorColor }) => {
  const { activeTab, setActiveTab } = useContext(OrderTabContext);
  const isActive = id === activeTab;

  const selectTab = () => {
    if (isActive) return;
    setActiveTab(id);
  }

  return (
    <div className={`tab${isActive ? ' active' : ''}`} onClick={() => selectTab()}>
      {isActive && <div className={`activeIndicator${id === 1 ? ' left' : ''}${id === 3 ? ' right' : ''}`}></div>}
      <Icon name={icon} size={20} />
      <span>{label}</span>{amountIndicator ? <span className="amountIndicator" style={{ color: amountIndicatorColor ? amountIndicatorColor : 'var(--onSurfaceLight)'}}>({amountIndicator})</span> : null}
    </div>
  )
}
import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { CacheSwitch } from 'react-router-cache-route';
import ProtectedRoute from "./routes/security/ProtectedRoute";
import { Login } from "./routes/Login";
import { AppContainer } from "./routes/containers/AppContainer";
import { IncapacitationWatcher } from "./components/general/IncapacitationWatcher";
import { Maintenance } from "./routes/Maintenance";

import "./style/components/Inputs.scss";
import "./style/components/Buttons.scss";
import "./style/components/Indicators.scss";
import "./style/components/Modals.scss";

const App = () => {
  return (
    <Router>
      <IncapacitationWatcher />
      <CacheSwitch>
        <Route exact path="/" render={() => (<Redirect to="/stores" />)} />
        <Route exact path="/login/:urlIdentifier" component={Login} />

        <ProtectedRoute exact path={[
          "/stores", "/stores/add", "/stores/added", "/store/:id", "/store/edit/:id",
          "/products", "/products/add", "/products/added", "/product/:id", "/product/edit/:id",
          "/orders", "/order/:id",
          "/order-summaries", "/order-summary/:id",
          "/group-actions", "/group-actions/order-settings", "/group-actions/special-days", "/group-actions/products",
          "/stats", "/stat/revenue", "/stat/orders",
          "/support",
          "/settings"
        ]} component={AppContainer} />

        <Route exact path="/maintenance" component={Maintenance} />
        <Route exact path="/404" render={() => <span>404 Not found</span>} />
        <Redirect to="/404" />
      </CacheSwitch>
    </Router>
  );
}

export default App;

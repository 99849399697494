import React from "react";

export const ActivityIndicator = () => {
  return (
    <div className="activityIndicator">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  )
}
export const convertToIntPrice = (input) => {
  return parseInt(parseFloat(input).toFixed(2).replace('.', ''));
}

// input should be in cents and as an int (e.g. 320 => 3,20€)
export const convertToCurrency = (input) => {
  return ((input / 100).toFixed(2) + "€").replace('.', ',');
}

export const convertToCurrencyDouble = (input) => {
  return (input / 100);
}

export const convertToPlainCurrency = (input) => {
  return ((input / 100).toFixed(2));
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { Feather as Icon } from 'react-web-vector-icons';
import { retrieveStoreListRequested } from "../actions/store";
import { GroupOpeningHours } from "../components/group-actions/GroupOpeningHours";
import { GroupOrderSettings } from "../components/group-actions/GroupOrderSettings";
import { GroupProducts } from "../components/group-actions/GroupProducts";
import { GroupSpecialDays } from "../components/group-actions/GroupSpecialDays";
import { GroupStoreEntry } from "../components/group-actions/GroupStoreEntry";
import { SelectAllStoresToggle } from "../components/group-actions/subcomponents/SelectAllStoresToggle";
import { SelectedStoresCounter } from "../components/group-actions/subcomponents/SelectedStoresCounter";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import { GroupActionStoreProvider } from "../helpers/contexts";
import "../style/routes/GroupActions.scss";
import "../style/routes/Store.scss";

export const GroupActions = ({ history }) => {
  const stores = useSelector(state => state.store.stores);
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveStoreListRequested());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredStores = (searchString === "") ? stores : stores && stores.filter(s => s.address.street.toLowerCase().includes(searchString.toLowerCase()));
  const storeList = filteredStores && filteredStores.map(s => <GroupStoreEntry key={s.id} {...s} />);

  return (
    <GroupActionStoreProvider>
      <div className="appContent">
        <div className="pageHeader">
          <div className="pageHeaderLeft">
            <span className="pageTitle">Gruppenaktionen</span>
            <span className="pageDescription">Ermöglicht es Aktionen auf mehrere Filialen anzuwenden</span>
          </div>
          <div className="pageHeaderRight">
            <SelectedStoresCounter />
          </div>
        </div>
        <div className="pageContent">
          <div className="card maxHeight leftList">
            <div className="searchHeader">
              <Icon name="search" color='var(--onSurfaceStrong)' size={20} />
              <input type="text" placeholder="Nach Straße filtern" value={searchString} onChange={e => setSearchString(e.target.value)} />
            </div>
            <div className="listContent hasSearchHeader">
              <SelectAllStoresToggle stores={stores} />
              {stores !== null ? storeList : <ActivityIndicator />}
              {filteredStores && filteredStores.length === 0 && <span className="emptyText">Keine Filialen gefunden.</span>}
            </div>
          </div>
          <div className="card maxHeight">
            <Route exact path="/group-actions" component={GroupOpeningHours} />
            <Route exact path="/group-actions/order-settings" component={GroupOrderSettings} />
            <Route exact path="/group-actions/special-days" component={GroupSpecialDays} />
            <Route exact path="/group-actions/products" component={GroupProducts} />
          </div>
        </div>
      </div>
    </GroupActionStoreProvider>
  )
}
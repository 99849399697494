import React from "react";
import { Feather as Icon } from 'react-web-vector-icons';

export const VariableDisplay = ({ label, value, marginBottom, boldValueText = true, trailingIcon, trailingIconColor, enableMaxWidth }) => {
  return (
    <div className={`variableDisplay${marginBottom ? ' marginBottom' : ''}${enableMaxWidth ? ' enableMaxWidth' : ''}`}>
      <span className="small">{label}</span>
      {trailingIcon ? (
        <div className="variableDisplaySplitter">
          <span className={boldValueText ? "bold" : ""}>{value}</span>
          <Icon name={trailingIcon} color={trailingIconColor} size={20} />
        </div>
      ) :
        <span className={boldValueText ? "bold" : ""}>{value}</span>}
    </div>
  )
}
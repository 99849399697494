export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const statusCodes = {
  // Success
  SUCCESS: 0,

  // Token Error (problem with/missing/invalid token)
  TOKEN_ERROR: 1,

  // Auth (no permission for action)
  AUTH_ERROR: 2,

  // HTTP
  INVALID_PARAMS: 3,

  // Unknown
  UNKNOWN_ERROR: 4,

  // Client version is outdated and no longer supported
  OUTDATED_CLIENT_VERSION: 5,

  // Server is undergoing maintenance
  ONGOING_MAINTENANCE: 6,

  // General
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

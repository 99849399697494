import { useDispatch, useSelector } from "react-redux"
import { setOpenModal } from "../../actions/general";
import { ModalType } from "../../helpers/modals";
import { DefaultButton } from "../buttons/DefaultButton";
import { DetailActionButton } from "../buttons/DetailActionButton";

export const GroupActionConfirmModal = () => {
    const openModal = useSelector((state) => state.general.openModal);
    const modalParams = useSelector((state) => state.general.modalParams);
    const stores = useSelector((state) => state.store.stores);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(setOpenModal(null));
    }

    const outsideClick = (e) => {
        if (e.target === e.currentTarget) closeModal();
    }

    const submit = () => {
        if (modalParams.onConfirm) {
            modalParams.onConfirm((success) => {
                if (success) closeModal();
            });
        }
    }

    return openModal === ModalType.GroupActionConfirm ? (
        <div className="modal-wrapper" onClick={(e) => outsideClick(e)}>
            <div className="modal">
                <div className="modal-header">
                    <div className="title">
                        <span className="big bold">{modalParams.title ? modalParams.title : ""}</span>
                    </div>
                    <div className="actions">
                        <DetailActionButton name="exit" icon="x" onPress={() => closeModal()} />
                    </div>
                </div>
                <div className="modal-content">
                    <div className="store-list">
                        {stores && stores.filter((s) => modalParams.selectedStoreIds.indexOf(s.id) !== -1).map((s) => {
                            const { street, streetNumber, postalCode, city } = s.address;
                            return (
                                <div key={s.id} className="store-entry">
                                    <div className="checkbox">
                                        {/* <CheckBox2 value={checked} /> */}
                                    </div>
                                    <div className="address">
                                        <span>{`${street} ${streetNumber}, ${postalCode} ${city}`}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="submit">
                        <DefaultButton text="Abbrechen" isFlat={true} onPress={() => closeModal()} />
                        <DefaultButton text="Bestätigen" onPress={() => submit()} />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}
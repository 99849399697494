import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editStoreRequested, setStoreImageRequested } from "../../actions/store";
import { DefaultButton } from "../buttons/DefaultButton";
import { DetailActionButton } from "../buttons/DetailActionButton";
import { DefaultInput } from "../inputs/DefaultInput";
import { OpeningHoursInput } from "../inputs/OpeningHoursInput";
import { ActivityIndicator } from "../loading/ActivityIndicator";
import { Duration } from 'luxon';
import { CheckBox } from "../inputs/CheckBox";

export const EditStore = ({ match, history }) => {
  const selectedStoreId = parseInt(match.params.selectedStoreId);
  const store = useSelector(state => state.store.stores ? state.store.stores.filter(s => s.id === selectedStoreId)[0] : null);
  const dispatch = useDispatch();

  const [storeData, setStoreData] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileRef = useRef();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (store) {
      const { name, phone, openingHours, disabled } = store;
      setStoreData({ name, phone, openingHours, disabled: !!disabled });
    }
  }, [store]);

  const changeStoreDataValue = (varName, varValue) => {
    setStoreData({ ...storeData, [varName]: varValue });
  }

  useEffect(() => {
    if (!selectedFile) {
      setImagePreview(null);
      return
    }
    setErrorMsg(null);
    const objectUrl = URL.createObjectURL(selectedFile)
    setImagePreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const navigateBack = () => history.push("/store/" + selectedStoreId);

  const submitChanges = () => {
    setLoading(true);

    // Check if openTime < closeTime
    let validOpeningHours = true;
    storeData.openingHours.forEach(h => {
      if (!validOpeningHours) return;
      const openTime = Duration.fromISOTime(h.openTime).toMillis();
      const closeTime = Duration.fromISOTime(h.closeTime).toMillis();
      const valid = openTime < closeTime; // Also returns false if invalid time
      if (!valid) validOpeningHours = false;
    });

    if (!validOpeningHours) {
      setLoading(false);
      setErrorMsg("Ungültige Öffnungszeiten. Bitte überprüfe die angegebenen Öffnungszeiten.");
      return;
    }

    setErrorMsg(null);
    dispatch(editStoreRequested(selectedStoreId, storeData, (success, error) => {
      if (success) {
        if (selectedFile) {
          dispatch(setStoreImageRequested(selectedStoreId, selectedFile, () => {
            setLoading(false);
            navigateBack();
          }));
        } else {
          setLoading(false);
          navigateBack();
        }
      } else {
        setLoading(false);
        setErrorMsg(error);
      }
    }));
  }

  return store && storeData ? (
    <React.Fragment>
      <div className="detailHeader">
        <div className="detailHeaderTitle">
          <span className="big">Details zur Filiale bearbeiten</span>
        </div>
        <div className="detailHeaderActions">
          <DetailActionButton name="close" icon="x" onPress={() => navigateBack()} />
        </div>
      </div>
      <div className="detailContent">
        <div className="editStore">
          <div className="storeInfoContainer">
            <DefaultInput name="Name" value={storeData.name} onChange={(e) => changeStoreDataValue("name", e.target.value)} />
            <DefaultInput name="Telefon" value={storeData.phone} onChange={(e) => changeStoreDataValue("phone", e.target.value)} />

            <div className="storeEnabledSwitchContainer">
              <CheckBox value={storeData.disabled} inverted={true} onChange={(v) => changeStoreDataValue("disabled", v)} />
              <span onClick={() => changeStoreDataValue("disabled", !storeData.disabled)}>Filiale {storeData.disabled && <u>nicht</u>} öffentlich anzeigen</span>
            </div>

            <OpeningHoursInput data={storeData.openingHours} onChange={(d) => changeStoreDataValue("openingHours", d)} />
            {errorMsg ? <span className="error">{errorMsg}</span> : null}
            <div className="editActions">
              <DefaultButton text="Speichern" loading={loading} onPress={() => submitChanges()} />
              {!loading && <DefaultButton text="Abbrechen" isFlat={true} onPress={() => navigateBack()} />}
            </div>
          </div>
          <div className="storeImageContainer">
            <div className="editImageWrapper" onClick={() => fileRef.current.click()}>
              <input ref={fileRef} type="file" name="file" onChange={onSelectFile} accept="image/x-png,image/jpeg" />
              <img src={imagePreview ? imagePreview : store.imageUrl} alt="Bild der Filiale" />
              <span className="big">Bild ändern</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  ) : <ActivityIndicator />
}
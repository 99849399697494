import { orderStates } from "../data/orderStates";
import { compareDateTimes } from "../utils/datetime";

const initialState = {
  openOrders: null,
  packedOrders: null,
  doneOrders: null,
  orders: {}, // Just for order details, lists are kept in fields above
  summaryStores: null,
  summaryOrders: null
};

export const order = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ORDER_OVERVIEW_SUCCEEDED": {
      return {
        ...state,
        summaryOrders: action.orderSummary
      }
    }
    case "RESET_ORDER_OVERVIEW_DATA": {
      return {
        ...state,
        summaryOrders: null
      }
    }
    case "GET_STORES_WITH_ORDERS_SUCCEEDED": {
      return {
        ...state,
        summaryStores: action.storeList
      }
    }
    case "INSERT_NEW_ORDER": {
      if (!state.openOrders) return state;

      let insertIndex = 0;
      state.openOrders.some((o, i) => {
        if (compareDateTimes(action.orderData.executionTime, o.executionTime) === 1) {
          insertIndex = i;
          return false;
        }
        return true;
      });

      const newOpenOrders = Array.from(state.openOrders);
      if (insertIndex === newOpenOrders.length - 1) {
        newOpenOrders.push(action.orderData);
      } else {
        newOpenOrders.splice(insertIndex, 0, action.orderData);
      }
      return {
        ...state,
        openOrders: newOpenOrders
      }
    }
    case "UPDATE_ORDER_STATE_SUCCEEDED": {
      if (!state.openOrders || !state.packedOrders || !state.doneOrders) return state;
      const { orderId, nextOrderState, reason } = action;

      // Find order entry
      let editedOrderData = {};
      const oo = state.openOrders.filter(o => o.id === orderId);
      if (oo.length > 0) editedOrderData = oo[0];
      else {
        const po = state.packedOrders.filter(o => o.id === orderId);
        if (po.length > 0) editedOrderData = po[0];
      }

      // Remove from all lists
      const newOpenOrders = state.openOrders.filter(o => o.id !== orderId);
      const newPackedOrders = state.packedOrders.filter(o => o.id !== orderId);
      const newDoneOrders = state.doneOrders.filter(o => o.id !== orderId);

      // Add to correct list
      const currentOrderState = nextOrderState; //orderStateHistory[orderStateHistory.length - 1];
      if ([orderStates.SUBMITTED, orderStates.CONFIRMED].indexOf(currentOrderState) !== -1) {
        newOpenOrders.push({ ...editedOrderData, currentOrderState });
      } else {
        if (currentOrderState === orderStates.PACKED) {
          newPackedOrders.push({ ...editedOrderData, currentOrderState });
        } else {
          newDoneOrders.push({ ...editedOrderData, currentOrderState });
        }
      }

      // Update in order details object
      const orderDetails = state.orders[orderId];

      return {
        ...state,
        openOrders: newOpenOrders,
        packedOrders: newPackedOrders,
        doneOrders: newDoneOrders,
        orders: orderDetails ? {
          ...state.orders,
          [orderId]: {
            ...orderDetails,
            orderStateHistory: [
              ...orderDetails.orderStateHistory,
              nextOrderState
            ],
            reason: reason || null
          }
        } : state.orders
      }
    }
    case "GET_ORDER_DETAILS_SUCCEEDED": {
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.orderId]: action.orderDetails
        }
      }
    }
    case "RETRIEVE_DONE_ORDERS_SUCCEEDED": {
      return {
        ...state,
        doneOrders: state.doneOrders ? [
          ...state.doneOrders,
          ...action.orders
        ] : action.orders
      }
    }
    case "RETRIEVE_PACKED_ORDERS_SUCCEEDED": {
      return {
        ...state,
        packedOrders: action.orders
      }
    }
    case "RETRIEVE_OPEN_ORDERS_SUCCEEDED": {
      return {
        ...state,
        openOrders: action.orders
      }
    }
    default:
      return state;
  }
};

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DefaultButton } from "../buttons/DefaultButton";
import { DefaultInput } from "../inputs/DefaultInput";
import { Feather as Icon } from 'react-web-vector-icons';
import { Route } from "react-router-dom";
import { LargeInput } from "../inputs/LargeInput";
import { SelectionInput } from "../inputs/SelectionInput";
import { AvailabilityModeSwitchHeader, AvailabilityDaySelection, SpecialAvailabilitySelection } from "./AvailabilitySelection";
import { addProductRequested, setProductImageRequested } from "../../actions/product";
import { taxOptions } from "../../data/productData";
import { convertToIntPrice } from "../../utils/conversion";
import { NutritionTable } from "./NutritionTable";
import { CheckBox } from "./../inputs/CheckBox";

export const AddProduct = ({ history, location }) => {
  const [productValues, setProductValues] = useState({ name: "", measure: "", price: "", description: "", ingredients: "", allergyAdvice: "", specialAvailability: [], categoryId: -1, taxId: 1 });
  const updateProductValue = (name, val) => setProductValues({ ...productValues, [name]: val });
  const [nutritionFacts, setNutritionFacts] = useState(null);
  const [nutritionFactsEnabled, setNutritionFactsEnabled] = useState(true);
  const [usingSpecialAvailability, setUsingSpecialAvailability] = useState(false);
  const categories = useSelector(state => state.product.categories);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileRef = useRef();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [createdProduct, setCreatedProduct] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedFile) {
      setImagePreview(null)
      return
    }
    setErrorMsg(null);
    const objectUrl = URL.createObjectURL(selectedFile)
    setImagePreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  useEffect(() => {
    if (location.pathname.split('/')[2] !== 'added') setCreatedProduct(false);
  }, [location]);

  const addProduct = () => {
    if (!selectedFile) {
      setErrorMsg("Bitte wähle zunächst ein Bild für dieses Produkt aus.");
      return;
    }
    if (productValues.categoryId === -1) {
      setErrorMsg("Bitte wähle die zugehörige Kategorie für das Produkt aus.");
      return;
    }

    setLoading(true);
    setErrorMsg(null);
    dispatch(addProductRequested({ 
      ...productValues, 
      nutritionFacts: { ...nutritionFacts, enabled: nutritionFactsEnabled ? 1 : 0 }, 
      price: convertToIntPrice(productValues.price),
      specialAvailability: usingSpecialAvailability ? productValues.specialAvailability : [] 
    }, handleResponse));
  }

  const handleResponse = (success, request) => {
    if (success) {
      if (request.result) {
        setCreatedProduct(true);
        history.push("/products/added");
        dispatch(setProductImageRequested(request.result.id, selectedFile, () => {
          setLoading(false);
          resetEverything();
        }));
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrorMsg(request.errorMsg);
    }
  }

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const resetEverything = () => {
    setProductValues({ name: "", measure: "", price: "", description: "", ingredients: "", allergyAdvice: "", specialAvailability: [], categoryId: -1, taxId: 1 });
    setSelectedFile(null);
    setImagePreview(null);
    setLoading(false);
    setErrorMsg(null);
  }

  return (
    <React.Fragment>
      <Route exact path="/products/add" render={() => (
        <React.Fragment>
          <div className="detailHeader">
            <span className="big">Neues Produkt anlegen</span>
          </div>
          <div className="detailContent">
            <div className="addProduct">
              <div className="productInfoContainer">
                <DefaultInput name="Bezeichnung" type="text" placeholder="Bezeichnung des Produkts" value={productValues["name"]} onChange={e => updateProductValue("name", e.target.value)} />
                <div className="halfSplitter">
                  <DefaultInput name="Gewicht/Anzahl" type="text" placeholder="z.B. 500g" value={productValues["measure"]} onChange={e => updateProductValue("measure", e.target.value)} />
                  <DefaultInput name="Preis" type="number" min={0} max={1000} step={0.1} placeholder="1,20" value={productValues["price"]} onChange={e => updateProductValue("price", e.target.value)} />
                  <span className="big medium currencySign">€</span>
                </div>
                <SelectionInput name="Steuersatz" data={taxOptions} onChange={e => updateProductValue("taxId", parseInt(e.target.value))} />
                <LargeInput name="Beschreibung" placeholder="Beschreibung des Produkts..." value={productValues["description"]} onChange={e => updateProductValue("description", e.target.value)} />
                <LargeInput name="Inhaltsstoffe" placeholder="Inhaltsstoffe des Produkts..." value={productValues["ingredients"]} onChange={e => updateProductValue("ingredients", e.target.value)} />
                <LargeInput name="Allergiehinweise" placeholder="Allergiehinweise des Produkts..." value={productValues["allergyAdvice"]} onChange={e => updateProductValue("allergyAdvice", e.target.value)} />
                <SelectionInput name="Kategorie" data={categories} onChange={e => updateProductValue("categoryId", parseInt(e.target.value))} />
              </div>
              <div className="productImageContainer">
                <input ref={fileRef} type="file" name="file" onChange={onSelectFile} accept="image/x-png,image/jpeg" />
                <div onClick={() => fileRef.current.click()}>
                  {selectedFile && imagePreview ?
                    <img src={imagePreview} alt="Produktbild" /> :
                    <div className="imagePlaceholder" >
                      <span>Bild auswählen</span>
                    </div>}
                </div>
              </div>
            </div>
            <div className="addProductBottom">
              <div className="availabilitySelection">
                <AvailabilityModeSwitchHeader usingSpecial={usingSpecialAvailability} setUsingSpecial={setUsingSpecialAvailability} />
                <AvailabilityDaySelection onChange={vals => updateProductValue("availability", vals)} hidden={usingSpecialAvailability} />
                <SpecialAvailabilitySelection hidden={!usingSpecialAvailability} data={productValues["specialAvailability"]} onChange={(sa) => updateProductValue("specialAvailability", sa)} />
              </div>
              <div className="nutritionFactsSwitchContainer">
                <CheckBox value={nutritionFactsEnabled} onChange={(v) => setNutritionFactsEnabled(v)} />
                <span onClick={() => setNutritionFactsEnabled(!nutritionFactsEnabled)}>Nährwerttabelle anzeigen</span>
              </div>
              <NutritionTable editMode={true} onChange={v => setNutritionFacts(v)} disabled={!nutritionFactsEnabled} />
              <span className="infoText">Hinweis: Einige Felder wie z. B. die Produktbezeichnung können nach der Erstellung nicht wieder geändert werden. Bitte überprüfen Sie daher Ihre Eingaben bevor Sie fortfahren.</span>
              {errorMsg ? <span className="error">{errorMsg}</span> : null}
              <DefaultButton text="Produkt erstellen" left={true} loading={loading} onPress={() => addProduct()} />
            </div>
          </div>
        </React.Fragment>
      )} />
      <Route exact path="/products/added" render={() => createdProduct ? (
        <div className="detailContent">
          <div className="addProductSucceeded">
            <Icon name="check" color='var(--success)' size={48} />
            <span className="successMsg big medium">Produkt erfolgreich angelegt!</span>
          </div>
        </div>
      ) : null} />
    </React.Fragment>
  )
}
const initialState = {
  totals: {
    revenue: null,
    orderCount: null,
    productsSold: null
  },
  revenueData: null,
  orderData: null
};

export const statistic = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DETAILED_ORDERS_SUCCEEDED": {
      return {
        ...state,
        orderData: action.stats
      }
    }
    case "GET_DETAILED_REVENUE_SUCCEEDED": {
      return {
        ...state,
        revenueData: action.stats
      }
    }
    case "GET_STATISTICS_SUCCEEDED": {
      const { revenue, orderCount, productsSold } = action.stats;
      return {
        ...state,
        totals: {
          revenue, orderCount, productsSold
        }
      }
    }
    default:
      return state;
  }
};

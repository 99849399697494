import React from "react";
import { getStandardGermanDate } from "../../../utils/datetime";
import { DefaultButton } from "../../buttons/DefaultButton";

export const SpecialHoursEntry = ({ index, date, openTime, closeTime, closed, reason, onRemove }) => {
    return (
        <div className="specialHoursEntry">
            <div className="top">
                <span className="date">{getStandardGermanDate(date)}</span>
                <span className="openValue">{closed ? `Geschlossen` : `Geöffnet von ${openTime} bis ${closeTime}`}</span>
                <DefaultButton text="Löschen" isDelete={true} isFlat={true} onPress={() => onRemove(index)} />
            </div>
            {reason ? <div className="bottom">
                <span className="reason">{reason}</span>
            </div> : null}
        </div>
    )
}
import React, { useEffect, useReducer } from "react";
import { getReadableUntilDateTime } from "../../utils/datetime";

export const OrderUntilTime = ({ executionTime }) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      forceUpdate();
    }, 5000);
    return () => clearInterval(refreshInterval);
  }, []);

  return (
    <span className="medium">{getReadableUntilDateTime(executionTime)}</span>
  )
}
import React from "react";
import { useHistory } from "react-router-dom";
import { Feather as Icon } from 'react-web-vector-icons';

export const ListKPI = ({ name, value, icon, navRoute, showChevron }) => {
  const history = useHistory();

  const handleClick = () => {
    if (navRoute) history.push(navRoute);
  }

  return (
    <div className="listKPI" onClick={() => handleClick()}>
      <div className="icon">
        <Icon name={icon} color='var(--onPrimary)' size={28} />
      </div>
      <div className="content">
        <span className="value">{value}</span>
        <span>{name}</span>
      </div>
      {showChevron && <div>
        <Icon name="chevron-right" color='var(--onSurfaceMedium)' size={24} />
      </div>}
    </div>
  )
}
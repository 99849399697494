import { baseUrl } from "./config";

export const basicAPIRequest = (path, body) => {
  return fetch(`${baseUrl}${path}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(res => {
      return res.json();
    })
    .catch(err => {
      throw err;
    });
};

export const authAPIRequest = (path, body, token) => {
  return fetch(`${baseUrl}${path}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  })
    .then(res => {
      return res.json();
    })
    .catch(err => {
      throw err;
    });
};

export const authAPIFormDataRequest = (path, formData, token) => {
  return fetch(`${baseUrl}${path}`, {
    method: 'post',
    headers: {
      // DO NOT SET CONTENT-TYPE!!! OTHERWISE UPLOAD WON'T WORK
      // 'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: formData
  })
    .then(res => {
      return res.json();
    })
    .catch(err => {
      throw err;
    });
};

export const workaroundDownload = (path, body, token) => {
  return fetch(`${baseUrl}${path}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  })
    .catch(err => {
      console.log("error downloading file")
      throw err;
    });
}

export const authAPIRequestWithCredentials = (path, body, token) => {
  return fetch(`${baseUrl}${path}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body),
    credentials: 'include'
  })
    .then(res => {
      return res.json();
    })
    .catch(err => {
      throw err;
    });
};
import { put, takeEvery, call, select } from "redux-saga/effects";
import { addGlobalMessageRequested } from "../actions/general";
import { addStoreSucceeded, deleteStoreSucceeded, editStoreSucceeded, retrieveStoreListSucceeded, setStoreImageSucceeded } from "../actions/store";
import { statusCodes } from "../api/config";
import { authAPIRequest, authAPIFormDataRequest } from "../api/requests";
import { notificationTypes } from "../data/notificatonTypes";
import { genericHandleResult } from "./sagaHelper";

export function* watchAllStoreActions() {
  yield takeEvery("RETRIEVE_STORE_LIST_REQUESTED", beginRetrieveStoreList);
  yield takeEvery("GET_STORE_COORDINATES_REQUESTED", beginGetStoreCoordinates);
  yield takeEvery("ADD_STORE_REQUESTED", beginAddStore);
  yield takeEvery("SET_STORE_IMAGE_REQUESTED", beginSetStoreImage);
  yield takeEvery("DELETE_STORE_REQUESTED", beginDeleteStore);
  yield takeEvery("EDIT_STORE_REQUESTED", beginEditStore);
  yield takeEvery("GROUP_SET_STORE_OPENING_HOURS_REQUESTED", beginGroupSetStoreOpeningHours);
  yield takeEvery("GROUP_SET_STORE_ORDER_SETTINGS_REQUESTED", beginGroupSetStoreOrderSettings);
  yield takeEvery("GROUP_ADD_STORE_SPECIAL_DAYS_REQUESTED", beginGroupAddStoreSpecialDays);
  yield takeEvery("GROUP_MODIFY_STORE_PRODUCTS_REQUESTED", beginGroupModifyStoreProducts);
}

function* beginRetrieveStoreList() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-stores", {}, token));

    yield genericHandleResult(
      request.statusCode,
      retrieveStoreListSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGetStoreCoordinates(action) {
  try {
    const { address, handleResponse } = action;
    const request = yield call(() => fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?limit=1&
    types=address&language=de&access_token=pk.eyJ1IjoibWJuZyIsImEiOiJja2xlOXIybHkwdHU2MnNtZ2E4YXU5YmZjIn0.flQptiLhNYkgU1TrBHtYAA`, {
      method: 'get',
    }).then(res => {
      return res.json();
    }).catch(err => {
      throw err;
    }));

    if (request.features.length < 1) handleResponse(false);
    const { relevance, geometry } = request.features[0];
    handleResponse(relevance > 0.6, { lat: geometry.coordinates[1], lng: geometry.coordinates[0] });
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginAddStore(action) {
  try {
    const { storeInfos, address, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/create-store", { ...storeInfos, address }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS, request);

    yield genericHandleResult(
      request.statusCode,
      addStoreSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginSetStoreImage(action) {
  try {
    const { storeId, imageData, handleResponse } = action;
    const token = yield select(state => state.authentication.token);

    const formData = new FormData();
    formData.append('file', imageData);
    formData.append('storeId', storeId);
    const request = yield call(() => authAPIFormDataRequest("/company/set-store-image", formData, token));

    handleResponse();
    yield genericHandleResult(
      request.statusCode,
      setStoreImageSucceeded(storeId, request.result ? request.result.url : null),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginDeleteStore(action) {
  try {
    const { storeId, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/delete-store", { storeId }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS);

    yield genericHandleResult(
      request.statusCode,
      deleteStoreSucceeded(storeId),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginEditStore(action) {
  try {
    const { storeId, storeData, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/edit-store", { storeId, ...storeData }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      editStoreSucceeded(storeId, storeData),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGroupSetStoreOpeningHours(action) {
  try {
    const { storeIds, openingHours, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/update-opening-hours", { storeIds, openingHours }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      addGlobalMessageRequested({ text: "Öffnungszeiten übernommen.", type: notificationTypes.SUCCESS, duration: 5000 }),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGroupSetStoreOrderSettings(action) {
  try {
    const { storeIds, orderTimeSettings, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/update-order-time-settings", { storeIds, orderTimeSettings }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      addGlobalMessageRequested({ text: "Bestelleinschränkungen übernommen.", type: notificationTypes.SUCCESS, duration: 5000 }),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGroupAddStoreSpecialDays(action) {
  try {
    const { storeIds, specialDays, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/add-special-days", { storeIds, specialDays }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      addGlobalMessageRequested({ text: "Sonderöffnungszeiten übernommen.", type: notificationTypes.SUCCESS, duration: 5000 }),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGroupModifyStoreProducts(action) {
  try {
    const { storeIds, productIds, operation, dailyAmountAvailable, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/modify-offered-products", { storeIds, productIds, operation, 
      dailyLimit: dailyAmountAvailable ? { amountMax: dailyAmountAvailable } : undefined }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      addGlobalMessageRequested({ text: "Sortiment-Änderungen übernommen.", type: notificationTypes.SUCCESS, duration: 5000 }),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}
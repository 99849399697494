import React from "react";

export const DefaultInput = ({ name, type, placeholder, value, onChange, disabled, ...rest }) => {

  return (
    <div className="defaultInput">
      <span className="small">{name}</span>
      <input type={type} placeholder={placeholder} value={value} onChange={onChange} size="1" disabled={disabled ? true : false} {...rest} />
    </div>
  )
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { Feather as Icon } from 'react-web-vector-icons';
import { retrieveStoreListRequested } from "../actions/store";
import { IconButton } from "../components/buttons/IconButton";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import { AddStore } from "../components/store/AddStore";
import { EditStore } from "../components/store/EditStore";
import { StoreDetails } from "../components/store/StoreDetails";
import { StoreEntry } from "../components/store/StoreEntry";
import "../style/routes/Store.scss";

export const Stores = ({ history }) => {
  const stores = useSelector(state => state.store.stores);
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveStoreListRequested());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredStores = (searchString === "") ? stores : stores && stores.filter(s => s.address.street.toLowerCase().includes(searchString.toLowerCase()));
  const storeList = filteredStores && filteredStores.map(s => <StoreEntry key={s.id} {...s} />);

  return (
    <div className="appContent">
      <div className="pageHeader">
        <div className="pageHeaderLeft">
          <span className="pageTitle">Filialverwaltung</span>
          <span className="pageDescription">Ermöglicht das Anlegen und Konfigurieren von Filialen</span>
        </div>
        <div className="pageHeaderRight">
          <IconButton icon="plus" text="Filiale hinzufügen" onPress={() => history.push("/stores/add")} />
        </div>
      </div>
      <div className="pageContent">
        <div className="card maxHeight leftList">
          <div className="searchHeader">
            <Icon name="search" color='var(--onSurfaceStrong)' size={20} />
            <input type="text" placeholder="Nach Straße filtern" value={searchString} onChange={e => setSearchString(e.target.value)} />
          </div>
          <div className="listContent hasSearchHeader">
            {stores !== null ? storeList : <ActivityIndicator />}
            {filteredStores && filteredStores.length === 0 && <span className="emptyText">Keine Filialen gefunden.</span>}
          </div>
        </div>
        <div className="card maxHeight">
          <Route exact path="/stores" render={() => <span className="emptyText">Bitte wähle eine Filiale aus</span>} />
          <Route exact path={["/stores/add", "/stores/added"]} component={AddStore} />
          <Route exact path="/store/:selectedStoreId" component={StoreDetails} />
          <Route exact path="/store/edit/:selectedStoreId" component={EditStore} />
        </div>
      </div>
    </div>
  )
}
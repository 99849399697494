import React, { useEffect, useState } from "react";
import { Feather as Icon } from 'react-web-vector-icons';
import { DateTime } from 'luxon';

export const BasicDateSwitch = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = useState(DateTime.now().plus({ day: 1 }));

  useEffect(() => {
    if (onChange) onChange(selectedDate.toISODate());
  }, [selectedDate, onChange]);

  const getDisplayDateString = () => {
    const now = DateTime.now();
    if (selectedDate.hasSame(now, 'month') && selectedDate.hasSame(now, 'year')) {
      if (selectedDate.hasSame(now, 'day')) {
        return "Heute";
      } else {
        if (selectedDate.hasSame(DateTime.now().minus({ day: 1 }), 'day')) {
          return "Gestern";
        } else {
          if (selectedDate.hasSame(DateTime.now().plus({ day: 1 }), 'day')) {
            return "Morgen";
          }
        }
      }
    }
    return selectedDate.toFormat("dd.LL.yyyy");
  }

  const goForward = () => {
    const newDate = selectedDate.plus({ day: 1 });
    setSelectedDate(newDate);
  }

  const goBackward = () => {
    const newDate = selectedDate.minus({ day: 1 });
    setSelectedDate(newDate);
  }

  return (
    <div className="basicDateSwitch">
      <div className="backward" onClick={() => goBackward()}>
        <Icon name="chevron-left" color='var(--onSurfaceStrong)' size={24} />
      </div>
      <div className="display">
        <span>{getDisplayDateString()}</span>
      </div>
      <div className="forward" onClick={() => goForward()}>
        <Icon name="chevron-right" color='var(--onSurfaceStrong)' size={24} />
      </div>
    </div>
  )
}
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Feather as Icon } from 'react-web-vector-icons';
import { removeGlobalMessage } from "../../actions/general";
import { notificationTypeInfos } from "../../data/notificatonTypes";

export const NotificationHandler = () => {
  const notifications = useSelector(state => state.general.notifications);
  return notifications.map((n, index) => <Notification key={n.id} {...n} index={index} />);
}

const Notification = ({ id, index, type, text }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    // if (orderId) history.push(`/order/${orderId}`);
    // dispatch(removeGlobalMessage(id));
  }

  const handleClose = (e) => {
    e.stopPropagation();
    dispatch(removeGlobalMessage(id));
  }

  return (
    <div className="notificationIndicator" style={{ top: (16 + index * (112 + 16)) + "px" }}>
      <div className={`innerContainer ${notificationTypeInfos[type].className}`} onClick={() => handleClick()}>
        <div className="top">
          <Icon name={notificationTypeInfos[type].icon} color={notificationTypeInfos[type].iconColor} size={24} />
          <span className="title">{notificationTypeInfos[type].name}</span>
          <div className="close" onClick={(e) => handleClose(e)}>
            <Icon name="x" color={notificationTypeInfos[type].iconColor} size={24} />
          </div>
        </div>
        <div className="bottom">
          <span>{text}</span>
        </div>
      </div>
    </div>
  );
}
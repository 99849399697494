import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProductRequested, setProductImageRequested } from "../../actions/product";
import { taxOptions } from "../../data/productData";
import { convertToPlainCurrency, convertToIntPrice } from "../../utils/conversion";
import { DefaultButton } from "../buttons/DefaultButton";
import { DetailActionButton } from "../buttons/DetailActionButton";
import { CheckBox } from "../inputs/CheckBox";
import { DefaultInput } from "../inputs/DefaultInput";
import { LargeInput } from "../inputs/LargeInput";
import { SelectionInput } from "../inputs/SelectionInput";
import { ActivityIndicator } from "../loading/ActivityIndicator";
import { VariableDisplay } from "../text/VariableDisplay";
import { AvailabilityModeSwitchHeader, AvailabilityDaySelection, SpecialAvailabilitySelection } from "./AvailabilitySelection";
import { NutritionTable } from "./NutritionTable";

export const EditProduct = ({ match, history }) => {
  const selectedProductId = parseInt(match.params.selectedProductId);
  const product = useSelector(state => state.product.products ? state.product.products.filter(p => p.id === selectedProductId)[0] : null);
  const [nutritionFacts, setNutritionFacts] = useState(null);
  const [nutritionFactsEnabled, setNutritionFactsEnabled] = useState(false);
  const categories = useSelector(state => state.product.categories);
  const [usingSpecialAvailability, setUsingSpecialAvailability] = useState(false);
  const dispatch = useDispatch();

  const [productData, setProductData] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileRef = useRef();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (product) {
      const { measure, price, description, ingredients, allergyAdvice, categoryId, availability, specialAvailability, taxId } = product;
      setProductData({ measure, price: convertToPlainCurrency(price), description, ingredients, allergyAdvice, categoryId, availability, specialAvailability, taxId });
      setUsingSpecialAvailability(specialAvailability && specialAvailability.length > 0);
    }
  }, [product]);

  const changeProductDataValue = (varName, varValue) => {
    setProductData({ ...productData, [varName]: varValue });
  }

  useEffect(() => {
    if (!selectedFile) {
      setImagePreview(null);
      return
    }
    setErrorMsg(null);
    const objectUrl = URL.createObjectURL(selectedFile)
    setImagePreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  useEffect(() => {
    if (product && product.nutritionFacts) setNutritionFactsEnabled(!!product.nutritionFacts.enabled);
  }, [product]);

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const navigateBack = () => history.push("/product/" + selectedProductId);

  const submitChanges = () => {
    setLoading(true);
    setErrorMsg(null);
    dispatch(editProductRequested(selectedProductId,
      {
        ...productData, 
        nutritionFacts: { ...nutritionFacts, enabled: nutritionFactsEnabled ? 1 : 0 },
        price: convertToIntPrice(productData.price), 
        specialAvailability: usingSpecialAvailability ? productData.specialAvailability : [] 
      }, (success, error) => {
        if (success) {
          if (selectedFile) {
            dispatch(setProductImageRequested(selectedProductId, selectedFile, () => {
              setLoading(false);
              navigateBack();
            }));
          } else {
            setLoading(false);
            navigateBack();
          }
        } else {
          setLoading(false);
          setErrorMsg(error);
        }
      }));
  }

  return product && productData ? (
    <React.Fragment>
      <div className="detailHeader">
        <div className="detailHeaderTitle">
          <span className="big">Produktdetails bearbeiten</span>
        </div>
        <div className="detailHeaderActions">
          <DetailActionButton name="close" icon="x" onPress={() => navigateBack()} />
        </div>
      </div>
      <div className="detailContent">
        <div className="editProduct">
          <div className="productInfoContainer">
            <VariableDisplay label="Name" value={product.name} marginBottom={true} />
            <div className="halfSplitter">
              <DefaultInput name="Gewicht/Anzahl" type="text" placeholder="z.B. 500g" value={productData.measure} onChange={e => changeProductDataValue("measure", e.target.value)} />
              <DefaultInput name="Preis" type="number" min={0} max={1000} step={0.1} placeholder="1,20" value={productData.price} onChange={e => changeProductDataValue("price", e.target.value)} />
              <span className="big medium currencySign">€</span>
            </div>
            <SelectionInput name="Steuersatz" value={productData.taxId} data={taxOptions} onChange={e => changeProductDataValue("taxId", parseInt(e.target.value))} />
            <LargeInput name="Beschreibung" placeholder="Beschreibung des Produkts..." value={productData.description || ""} onChange={e => changeProductDataValue("description", e.target.value)} />
            <LargeInput name="Inhaltsstoffe" placeholder="Inhaltsstoffe des Produkts..." value={productData.ingredients || ""} onChange={e => changeProductDataValue("ingredients", e.target.value)} />
            <LargeInput name="Allergiehinweise" placeholder="Allergiehinweise des Produkts..." value={productData.allergyAdvice || ""} onChange={e => changeProductDataValue("allergyAdvice", e.target.value)} />
            <SelectionInput name="Kategorie" value={productData.categoryId} data={categories} onChange={e => changeProductDataValue("categoryId", parseInt(e.target.value))} />
          </div>
          <div className="productImageContainer">
            <div className="editImageWrapper" onClick={() => fileRef.current.click()}>
              <input ref={fileRef} type="file" name="file" onChange={onSelectFile} accept="image/x-png,image/jpeg" />
              <img src={imagePreview ? imagePreview : product.imageUrl} alt="Produktbild" />
              <span className="big">Bild ändern</span>
            </div>
          </div>
        </div>
        <div className="editProductBottom">
          <div className="availabilitySelection">
            <AvailabilityModeSwitchHeader usingSpecial={usingSpecialAvailability} setUsingSpecial={setUsingSpecialAvailability} />
            <AvailabilityDaySelection data={productData.availability} onChange={vals => changeProductDataValue("availability", vals)} hidden={usingSpecialAvailability} />
            <SpecialAvailabilitySelection hidden={!usingSpecialAvailability} data={productData.specialAvailability} onChange={(sa) => changeProductDataValue("specialAvailability", sa)} />
          </div>
          <div className="nutritionFactsSwitchContainer">
            <CheckBox value={nutritionFactsEnabled} onChange={(v) => setNutritionFactsEnabled(v)} />
            <span onClick={() => setNutritionFactsEnabled(!nutritionFactsEnabled)}>Nährwerttabelle anzeigen</span>
          </div>
          <NutritionTable editMode={true} nutritionFacts={product.nutritionFacts} onChange={v => setNutritionFacts(v)} disabled={!nutritionFactsEnabled} />
          {errorMsg ? <span className="error">{errorMsg}</span> : null}
          <div className="editActions">
            <DefaultButton text="Speichern" loading={loading} onPress={() => submitChanges()} />
            {!loading && <DefaultButton text="Abbrechen" isFlat={true} onPress={() => navigateBack()} />}
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : <ActivityIndicator />
}
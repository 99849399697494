export const addGlobalMessageRequested = (message) => ({
  type: "ADD_GLOBAL_MESSAGE_REQUESTED",
  message
});

export const addGlobalMessage = (message) => ({
  type: "ADD_GLOBAL_MESSAGE",
  message
});

export const removeGlobalMessage = (id) => ({
  type: "REMOVE_GLOBAL_MESSAGE",
  id
});

export const clearGlobalMessages = () => ({
  type: "CLEAR_GLOBAL_MESSAGES"
});

export const setMaintenanceMode = (value) => ({
  type: "SET_MAINTENANCE_MODE",
  value
});

export const checkMaintenanceModeStatus = () => ({
  type: "CHECK_MAINTENANCE_MODE_STATUS"
});

export const setOpenModal = (value, params) => ({
  type: "SET_OPEN_MODAL",
  value,
  params
});
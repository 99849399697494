import { select, put, takeEvery, call } from 'redux-saga/effects';
import { addGlobalMessageRequested } from '../actions/general';
import { notificationTypes } from '../data/notificatonTypes';
import { authAPIRequest, workaroundDownload } from "../api/requests";
import { genericHandleResult } from "./sagaHelper";
import { getDetailedOrdersSucceeded, getDetailedRevenueSucceeded, getStatisticsSucceeded } from '../actions/statistic';
import { statisticTypes } from '../data/statisticTypes';

export function* watchAllStatisticActions() {
  yield takeEvery("GET_STATISTICS_REQUESTED", beginGetStatistics);
  yield takeEvery("GET_DETAILED_REVENUE_REQUESTED", beginGetDetailedRevenue);
  yield takeEvery("GET_DETAILED_ORDERS_REQUESTED", beginGetDetailedOrders);
  yield takeEvery("DOWNLOAD_ORDER_SUMMARY_REQUESTED", beginDownloadOrderSummary);
}

function* beginGetStatistics(action) {
  try {
    const { timeSpan, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-stats", { timespan: timeSpan }, token));
    handleResponse();

    yield genericHandleResult(
      request.statusCode,
      getStatisticsSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGetDetailedRevenue(action) {
  try {
    const { timeSpan } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-stats-details", { timespan: timeSpan, type: statisticTypes.REVENUE }, token));

    yield genericHandleResult(
      request.statusCode,
      getDetailedRevenueSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGetDetailedOrders(action) {
  try {
    const { timeSpan } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/company/get-stats-details", { timespan: timeSpan, type: statisticTypes.ORDERS }, token));

    yield genericHandleResult(
      request.statusCode,
      getDetailedOrdersSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginDownloadOrderSummary(action) {
  try {
    const { timeSpan, fileFormat } = action;
    const token = yield select(state => state.authentication.token);
    const dlresult = yield call(() => workaroundDownload("/company/download-order-stats", { timespan: timeSpan, fileFormat }, token));
    const contentType = dlresult.headers.get("Content-Type");

    if (contentType.startsWith("application/json")) {
      const request = yield dlresult.json();
      yield genericHandleResult(
        request.statusCode,
        null,
        addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
      );
    } else {
      const blob = yield dlresult.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = "bestellungen." + fileFormat;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}
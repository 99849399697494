// 1. vorläufig bis zu gewisser zeit -> gibt garantiert vor zeit und vorläufig nach zeit
// 2. immer alles annehmen/ablehnen -> es wird immer vorläufig angezeigt
// 3. nie nach gewisser zeit -> gibt nur garantiert und gar nicht, abhängig von zeit
// 4. nie nach gewisser zeit und alles vorher muss angenommen werden
// PROVISIONALLY DISPLAY: 1. zeigt berechnung an ab wann garantiert, 2. + 3. zeigen an, dass angenommen werden muss

export const orderAcceptanceModes = {
  BASIC_GUARANTEE: 1,
  ALWAYS_PROVISIONALLY: 2,
  GUARANTEE_OR_NONE: 3,
  PROVISIONALLY_OR_NONE: 4,
};

export const orderAcceptanceModeData = [ // In this format for the SelectionInput component
  { id: orderAcceptanceModes.BASIC_GUARANTEE, name: "Vorläufige und zeitabhängige Garantie" }, // BASIC_GUARANTEE
  { id: orderAcceptanceModes.ALWAYS_PROVISIONALLY, name: "Annahmepflicht ohne Zeitbeschränkung" }, // ALWAYS_PROVISIONALLY
  { id: orderAcceptanceModes.GUARANTEE_OR_NONE, name: "Strenge Zeitbeschränkung" }, // GUARANTEE_OR_NONE
  { id: orderAcceptanceModes.PROVISIONALLY_OR_NONE, name: "Strenge Zeitbeschränkung mit Annahmepflicht" }, // PROVISIONALLY_OR_NONE
];

export const orderAcceptanceModeDetails = {
  [orderAcceptanceModes.BASIC_GUARANTEE]: { desc: "Bis zum gewählten Zeitpunkt sind alle Bestellungen garantiert. Nach dem gewählten Zeitpunkt kann dennoch bestellt werden, diese Bestellungen müssen jedoch manuell angenommen/abgelehnt werden." },
  [orderAcceptanceModes.ALWAYS_PROVISIONALLY]: { desc: "Bestellungen sind zu keinem Zeitpunkt garantiert. Jede Bestellung muss manuell angenommen/abgelehnt werden." },
  [orderAcceptanceModes.GUARANTEE_OR_NONE]: { desc: "Bestellungen können nur bis zu einem festen Zeitpunkt getätigt werden und sind dann garantiert." },
  [orderAcceptanceModes.PROVISIONALLY_OR_NONE]: { desc: "Bestellungen können nur bis zu einem festen Zeitpunkt getätigt werden und müssen zudem manuell angenommen/abgelehnt werden." },
}
const DynamicManifest = (companyName) => ({
  "short_name": "brotbox",
  "name": "brotbox: Verwaltung",
  "icons": [
    {
      "src": "favicon.ico",
      "sizes": "64x64 32x32 24x24 16x16",
      "type": "image/x-icon"
    }
  ],
  "start_url": companyName ? `${window.location.origin}/login/${companyName}` : ".",
  "display": "standalone",
  "theme_color": "#000000",
  "background_color": "#ffffff"
});

export const setDynamicManifest = (companyName) => {
  const stringManifest = JSON.stringify(DynamicManifest(companyName));
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector('#dynamic-manifest').setAttribute('href', manifestURL);
}
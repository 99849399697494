import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Feather as Icon } from 'react-web-vector-icons';
import { resetOrderOverviewData } from "../../actions/order";

export const OrderSummaryAllEntry = ({ selectedStoreId }) => {
  const isSelected = selectedStoreId === "all";
  const history = useHistory();
  const dispatch = useDispatch();

  const selectAll = () => {
    if (isSelected) return;
    dispatch(resetOrderOverviewData());
    history.push("/order-summary/all");
  }

  return (
    <div className={`defaultListElement${isSelected ? ' selected' : ''}`} onClick={() => selectAll()}>
      <div className="elemImageContainer">
        <div className="imgEmpty centerContent">
          <Icon name={"layers"} color='var(--onSurfaceStrong)' size={32} />
        </div>
      </div>
      <div className="elemInfoContainer">
        <span className="medium">Alle Filialen</span>
      </div>
      <div className="selectedIndicator" />
    </div>
  )
}
import React from "react";
import { useGroupActionStoreContext } from "../../helpers/contexts";
import { CheckBox2 } from "../inputs/CheckBox";

export const GroupStoreEntry = ({ id, address, name }) => {
  const { street, streetNumber, postalCode, city } = address;

  const { selectedStoreIds, setSelectedStoreIds } = useGroupActionStoreContext();
  const checked = selectedStoreIds.indexOf(id) !== -1;

  const handleOnToggleStore = () => {
    if (selectedStoreIds.indexOf(id) === -1) {
      const newSelectedStores = [...selectedStoreIds];
      newSelectedStores.push(id);
      setSelectedStoreIds(newSelectedStores);
    } else {
      const index = selectedStoreIds.indexOf(id);
      if (index !== -1) {
        const newSelectedStores = [...selectedStoreIds];
        newSelectedStores.splice(index, 1);
        setSelectedStoreIds(newSelectedStores)
      }
    }
  }

  return (
    <div className={`groupActionListElement ${checked ? 'selected' : ''}`} onClick={() => handleOnToggleStore()}>
      <div className="checkContainer">
        <CheckBox2 value={checked} />
      </div>
      <div className="elemInfoContainer">
        <span className="medium">{name}</span>
        <span>{`${street} ${streetNumber}`}</span>
        <span>{`${postalCode} ${city}`}</span>
      </div>
      <div className="selectedIndicator" />
    </div>
  )
}
export const companyLookupRequested = (urlIdentifier, handleResponse) => ({
  type: "COMPANY_LOOKUP_REQUESTED",
  urlIdentifier,
  handleResponse
});

export const setCompanyInfos = (id, name, colors, urlIdentifier, isWhitelabel) => ({
  type: "SET_COMPANY_INFOS",
  id,
  name,
  colors,
  urlIdentifier,
  isWhitelabel
});

export const getCompanySettingsRequested = (handleResponse) => ({
  type: "GET_COMPANY_SETTINGS_REQUESTED",
  handleResponse
});

export const getCompanySettingsSucceeded = (companySettings) => ({
  type: "GET_COMPANY_SETTINGS_SUCCEEDED",
  companySettings
});

export const setCompanyLogoRequested = (imageData, handleResponse) => ({
  type: "SET_COMPANY_LOGO_REQUESTED",
  imageData, handleResponse
});

export const setCompanyLogoSucceeded = (url) => ({
  type: "SET_COMPANY_LOGO_SUCCEEDED",
  url
});

export const setSurprisePackageSettingsRequested = (surprisePackageSettings, handleResponse) => ({
  type: "SET_SURPRISE_PACKAGE_SETTINGS_REQUESTED",
  surprisePackageSettings, handleResponse
});

export const setSurprisePackageSettingsSucceeded = (surprisePackageSettings) => ({
  type: "SET_SURPRISE_PACKAGE_SETTINGS_SUCCEEDED",
  surprisePackageSettings
});
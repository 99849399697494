import React, { useEffect, useState } from "react";
import { Feather as Icon } from 'react-web-vector-icons';
import { DefaultButton } from "../buttons/DefaultButton";
import { DefaultInput } from "../inputs/DefaultInput";
import { VariableDisplay } from "../text/VariableDisplay";
import { DateTime } from 'luxon';

const initialData = {
  monday: 1,
  tuesday: 1,
  wednesday: 1,
  thursday: 1,
  friday: 1,
  saturday: 1,
  sunday: 1
}

export const AvailabilityModeSwitchHeader = ({ usingSpecial, setUsingSpecial }) => {
  return (
    <div className="switchHeader" style={{ display: "flex", flexDirection: "row" }}>
      <span className={`switchOption small ${!usingSpecial ? "selected" : ""}`} onClick={() => setUsingSpecial(false)}>Tagesbasierte Verfügbarkeit</span>
      <span className="separator">|</span>
      <span className={`switchOption small ${usingSpecial ? "selected" : ""}`} onClick={() => setUsingSpecial(true)}>Spezielle Verfügbarkeit</span>
    </div>
  )
}

export const AvailabilityDaySelection = ({ data, onChange, hidden }) => {
  const dayShortName = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
  const [dayAvailability, setDayAvailability] = useState(data ? { ...data } : initialData);

  useEffect(() => {
    onChange(dayAvailability);
  }, [dayAvailability]); // eslint-disable-line react-hooks/exhaustive-deps

  return hidden ? null : (
    <div className="dayList">
      {Object.keys(dayAvailability).map((d, i) => <div
        onClick={() => setDayAvailability({ ...dayAvailability, [d]: dayAvailability[d] ? 0 : 1 })}
        className={`dayOption${dayAvailability[d] ? ' available' : ''}`}
        key={d}
      >
        <Icon name={dayAvailability[d] ? 'check' : 'x'} color='var(--onSurfaceLight)' size={18} />
        <span>{dayShortName[i]}</span>
      </div>)}
    </div>
  )
}

export const SpecialAvailabilitySelection = ({ data, onChange, hidden }) => {

  const onAdd = (timespanObj) => {
    onChange([...data, timespanObj]);
  }

  const onDelete = (index) => {
    let newData = Array.from(data);
    newData.splice(index, 1);
    onChange(newData);
  }

  return hidden ? null : (
    <>
      <div>
        {data && data.map((s, i) => <SpecialAvailabilitySpan key={`sas${i}`} {...s} index={i} onDelete={onDelete} />)}
      </div>
      <AddSpecialAvailability data={data} onAdd={onAdd} />
    </>
  )
}

const SpecialAvailabilitySpan = ({ startDate, endDate, index, onDelete }) => {
  return (
    <div className="specialAvailabilitySpan">
      <VariableDisplay label="Von" value={DateTime.fromFormat(startDate, "yyyy-LL-dd").toFormat("dd.LL.yyyy")} />
      <VariableDisplay label="Bis" value={DateTime.fromFormat(endDate, "yyyy-LL-dd").toFormat("dd.LL.yyyy")} />
      <div className="deleteOption" onClick={() => onDelete(index)}>
          <Icon name="x" color='var(--onSurfaceStrong)' size={20} />
        </div>
    </div>
  )
}

const AddSpecialAvailability = ({ data, onAdd }) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [startDateIn, setStartDateIn] = useState("");
  const [endDateIn, setEndDateIn] = useState("");

  const handleAdd = () => {
    const startDateObject = DateTime.fromFormat(startDateIn, "dd.LL.yyyy");
    if (!startDateObject.isValid) {
      setErrorMsg("Das angegebene Von-Datum ist ungültig.");
      return;
    }
    const endDateObject = DateTime.fromFormat(endDateIn, "dd.LL.yyyy");
    if (!endDateObject.isValid) {
      setErrorMsg("Das angegebene Bis-Datum ist ungültig.");
      return;
    }

    const timeSpanToAdd = { startDate: startDateObject.toFormat("yyyy-LL-dd"), endDate: endDateObject.toFormat("yyyy-LL-dd") };
    const tempNewData = [...data, timeSpanToAdd];
    if (hasTimespanOverlap(tempNewData)) {
      setErrorMsg("Die angegebenen Zeitspannen überschneiden sich.");
      return;
    }

    onAdd(timeSpanToAdd);
    setStartDateIn("");
    setEndDateIn("");
    setErrorMsg("");
  }

  return (
    <div className="addSpecialAvailability">
      <div className="inputFields">
        <DefaultInput name="Von" type="text" placeholder="z.B. 01.01.2022" value={startDateIn} onChange={(e) => setStartDateIn(e.target.value)} />
        <DefaultInput name="bis" type="text" placeholder="z.B. 03.01.2022" value={endDateIn} onChange={(e) => setEndDateIn(e.target.value)} />
        <DefaultButton text="Hinzufügen" onPress={() => handleAdd()} />
      </div>
      {errorMsg ? <span className="error">{errorMsg}</span> : null}
    </div>
  )
}

const hasTimespanOverlap = (arr) => {
  return arr.map((item, index) => {
      const s = item.startDate;
      const e = item.endDate;

      const res = arr.filter((innerItem, innerIndex) => {
          if (index === innerIndex)
              return false;

          const iS = innerItem.startDate;
          const iE = innerItem.endDate;

          return (iS <= s && iE >= s) || (iE >= s && iE <= e)
      });
      return res.length > 0;
  }).includes(true);
};
export const getCategoriesRequested = () => ({
  type: "GET_CATEGORIES_REQUESTED"
});

export const getCategoriesSucceeded = (categoryList) => ({
  type: "GET_CATEGORIES_SUCCEEDED",
  categoryList
});

export const retrieveProductListRequested = () => ({
  type: "RETRIEVE_PRODUCT_LIST_REQUESTED"
});

export const retrieveProductListSucceeded = (productList) => ({
  type: "RETRIEVE_PRODUCT_LIST_SUCCEEDED",
  productList
});

export const addProductRequested = (productData, handleResponse) => ({
  type: "ADD_PRODUCT_REQUESTED",
  productData, handleResponse
});

export const addProductSucceeded = (productData) => ({
  type: "ADD_PRODUCT_SUCCEEDED",
  productData
});

export const setProductImageRequested = (productId, imageData, handleResponse) => ({
  type: "SET_PRODUCT_IMAGE_REQUESTED",
  productId, imageData, handleResponse
});

export const setProductImageSucceeded  = (productId, url) => ({
  type: "SET_PRODUCT_IMAGE_SUCCEEDED",
  productId, url
});

export const deleteProductRequested = (productId, handleResponse) => ({
  type: "DELETE_PRODUCT_REQUESTED",
  productId, handleResponse
});

export const deleteProductSucceeded = (productId) => ({
  type: "DELETE_PRODUCT_SUCCEEDED",
  productId
});

export const editProductRequested = (productId, productData, handleResponse) => ({
  type: "EDIT_PRODUCT_REQUESTED",
  productId, productData, handleResponse
});

export const editProductSucceeded = (productId, productData) => ({
  type: "EDIT_PRODUCT_SUCCEEDED",
  productId, productData
});
import { put, takeEvery, call, select } from "redux-saga/effects";
import { companyLoginSucceeded } from "../actions/authentication";
import { addGlobalMessageRequested } from "../actions/general";
import { statusCodes } from "../api/config";
import { authAPIRequestWithCredentials, basicAPIRequest } from "../api/requests";
import { notificationTypes } from "../data/notificatonTypes";
import { genericHandleResult } from "./sagaHelper";
// import { basicAPIRequest } from "../api/requests";

export function* watchAllAuthenticationActions() {
  yield takeEvery("COMPANY_LOGIN_REQUESTED", beginCompanyLogin);
  yield takeEvery("AUTO_LOGIN_STORE_REQUESTED", beginAutoLoginStore);
}

function* beginCompanyLogin(action) {
  try {
    const { username, password, companyId, handleResult } = action;
    const request = yield call(() => basicAPIRequest("/company/login", { email: username, password, companyId }));

    switch (request.statusCode) {
      case statusCodes.SUCCESS: {
        handleResult(true);
        yield put(companyLoginSucceeded(request.result.token));
        break;
      }
      case statusCodes.AUTH_ERROR: {
        handleResult(false);
        // yield put(addGlobalMessageRequested({ text: "Die angegebenen Anmeldedaten sind ungültig. Bitte versuche es erneut.", duration: 3000, type: messageTypes.ERROR }));
        break;
      }
      case statusCodes.INVALID_PARAMS: {
        handleResult(false);
        break;
      }
      default: {
        handleResult(false);
        yield put(addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR }))
        break;
      }
    }

  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginAutoLoginStore(action) {
  try {
    const { storeId, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequestWithCredentials("/company/auto-login", { storeId }, token));
    handleResponse(request.statusCode === statusCodes.SUCCESS);

    yield genericHandleResult(
      request.statusCode,
      undefined,
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}
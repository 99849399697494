import { DateTime, Duration } from 'luxon';

// in Vergangenheit: am 01.02.2021 10:50 Uhr
// < 1h => in XX min
// < 3h => in X h XX min
// < 1T => in X h
// => X Tage
export const getReadableUntilDateTime = (dateTimeString) => {
  const now = DateTime.now();
  const tt = DateTime.fromISO(dateTimeString);

  if (tt < now) {
    return tt.toFormat("'am' dd.LL.yyyy HH:mm 'Uhr'");
  }

  const diff = tt.diff(now);
  if (diff.as('hours') < 1) {
    return `in ${diff.as('minutes').toFixed(0)} min`;
  } else {
    if (diff.as('hours') < 3) {
      const diffO = diff.shiftTo('hours', 'minutes').toObject();
      return `in ${diffO.hours.toFixed(0)} h ${diffO.minutes.toFixed(0)} min`;
    } else {
      if (diff.as('days') < 1) {
        return `in ${diff.as('hours').toFixed(0)} h`;
      } else {
        const days = diff.as('days').toFixed(0);
        return `in ${days} ${pluralize("Tag", "en", days)}`;
      }
    }
  }
}

export const getReadableDateTime = (dateTimeString) => {
  return DateTime.fromISO(dateTimeString).toFormat("dd.LL.yyyy HH:mm");
}

export const isInPast = (dateTimeString) => {
  return (DateTime.fromISO(dateTimeString) < DateTime.now()) ? true : false;
}

export const getStandardGermanDate = (dateString) => {
  return DateTime.fromISO(dateString).toFormat("dd.LL.yyyy");
}

export const getSingleDayFromDate = (dateString) => {
  return DateTime.fromISO(dateString).toFormat("d");
}

// export const isInFuture = (dateTimeString) => {
//   return (DateTime.fromISO(dateTimeString) > DateTime.now()) ? true : false;
// }

export const compareDateTimes = (aString, bString) => {
  const a = DateTime.fromISO(aString);
  const b = DateTime.fromISO(bString);
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export const pluralize = (word, pluralEnding, amount) => {
  return (parseInt(amount) === 1) ? word : word + pluralEnding;
}

const TIME_REGEX = /^([01]\d|2[0-3]):?([0-5]\d)$/;
export const isValidTime = (timeString) => {
  if (!timeString) return false;
  if (TIME_REGEX.test(timeString)) {
    const dur = Duration.fromISOTime(timeString);
    return dur.isValid && dur.hours < 24;
  }
  return false;
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { setOpenModal } from "../../actions/general";
import { ModalType } from "../../helpers/modals";
import { DefaultButton } from "../buttons/DefaultButton";
import { DetailActionButton } from "../buttons/DetailActionButton";
import { DefaultInput } from "../inputs/DefaultInput";
import { updateOrderStateRequested } from "../../actions/order";
import { orderStates } from "../../data/orderStates";

export const MainReasonType = {
    NothingAvailable: 1,
    OnlyPartiallyAvailable: 2,
    NoReason: 3,
    CustomReason: 4
}

export const RejectionReasonModal = () => {
    const openModal = useSelector((state) => state.general.openModal);
    const modalParams = useSelector((state) => state.general.modalParams);

    const [mainReason, setMainReason] = useState();
    const [secondaryReasons, setSecondaryReasons] = useState([]); // [{ id, name, amountAvailable, totalAmount }]
    const [finalReasonString, setFinalReasonString] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (openModal !== ModalType.RejectionReason) {
            setMainReason(undefined);
            setSecondaryReasons([]);
            setFinalReasonString("");
        }
    }, [openModal, modalParams]);

    const closeModal = () => {
        dispatch(setOpenModal(null));
    }

    const outsideClick = (e) => {
        if (e.target === e.currentTarget) closeModal();
    }

    const toggleSecondaryReason = (id, name, amount) => {
        const reasonIndex = secondaryReasons.findIndex((r) => r.id === id);
        if (reasonIndex > -1) {
            const newSecondaryReasons = Array.from(secondaryReasons);
            newSecondaryReasons.splice(reasonIndex, 1);
            setSecondaryReasons(newSecondaryReasons);
        } else {
            setSecondaryReasons([...secondaryReasons, { id, name, amountAvailable: amount, totalAmount: amount }]);
        }
    }

    const changeSecondaryReasonAmount = (id, changeAmount, maxAmount) => {
        const reasonIndex = secondaryReasons.findIndex((r) => r.id === id);
        if (reasonIndex === -1) return;

        const newSecondaryReasons = Array.from(secondaryReasons);
        const newAmount = newSecondaryReasons[reasonIndex].amountAvailable + changeAmount;
        if (newAmount < 0 || newAmount > maxAmount) return;

        newSecondaryReasons[reasonIndex] = { ...newSecondaryReasons[reasonIndex], amountAvailable: newAmount };
        setSecondaryReasons(newSecondaryReasons);
    }

    const submit = () => {
        let updateReasonString = finalReasonString;
        if (mainReason === MainReasonType.OnlyPartiallyAvailable) {
            let productString = ": ";
            secondaryReasons.forEach((r, index) => {
                productString += `${r.name} (Nur ${r.amountAvailable} von ${r.totalAmount})${index === secondaryReasons.length - 1 ? '' : ', '}`;
            });
            updateReasonString += productString;
        }

        dispatch(updateOrderStateRequested(modalParams.id, orderStates.DECLINED, updateReasonString, (loading) => {
            setLoading(loading);
            if (!loading) closeModal();
        }));
    }

    const mainReasonSelection = () => (
        <React.Fragment>
            <span>Aus welchem Grund soll die Bestellung abgelehnt werden?</span>
            <div className="reason-list">
                <ReasonComponent text="Alle Produkte nicht verfügbar" reasonType={MainReasonType.NothingAvailable} />
                <ReasonComponent text="Einzelne Produkte nicht verfügbar" reasonType={MainReasonType.OnlyPartiallyAvailable} />
                <ReasonComponent text="Keinen Grund angeben" reasonType={MainReasonType.NoReason} noReasonText={true} />
                <ReasonComponent text="Eigene Nachricht verfassen" reasonType={MainReasonType.CustomReason} noReasonText={true} />
            </div>
        </React.Fragment>
    )

    const ReasonComponent = ({ text, reasonType, noReasonText }) => (
        <div className={`reason${mainReason === reasonType ? ' selected' : ''}`} onClick={() => {
            setMainReason(reasonType);
            setFinalReasonString(noReasonText ? '' : text);
        }}>
            <span>{text}</span>
        </div>
    )

    const SecondaryReasonComponent = ({ id, name, amount }) => {
        const reason = secondaryReasons.find((r) => r.id === id);
        return (
            <div className={`secondary-reason${reason ? ' selected' : ''}`}>
                <span className="name" onClick={() => toggleSecondaryReason(id, name, amount)}>{name}</span>
                <div className="amount-picker">
                    {reason && <DetailActionButton icon="minus" onPress={() => changeSecondaryReasonAmount(id, -1, amount)} />}
                    <span>{reason ? reason.amountAvailable : amount} von {amount}</span>
                    {reason && <DetailActionButton icon="plus" onPress={() => changeSecondaryReasonAmount(id, 1, amount)} />}
                </div>
            </div>)
    }

    const secondaryReasonSelection = () => {
        switch (mainReason) {
            case MainReasonType.NothingAvailable: {
                return null;
            }
            case MainReasonType.OnlyPartiallyAvailable: {
                return (
                    <div className="secondary-reason-container">
                        <span>Welche Produkte sind nicht verfügbar?</span>
                        {modalParams.products.map((p) => (
                            <SecondaryReasonComponent key={`src${p.id}`} {...p} />
                        ))}
                    </div>
                )
            }
            case MainReasonType.NoReason: {
                break;
            }
            case MainReasonType.CustomReason: {
                return (
                    <div className="secondary-reason-container">
                        <DefaultInput name="Grund:" type="input" value={finalReasonString} onChange={(e) => setFinalReasonString(e.target.value)} />
                    </div>
                )
            }
            default: {
                break;
            }
        }
    }

    return openModal === ModalType.RejectionReason ? (
        <div className="modal-wrapper" onClick={(e) => outsideClick(e)}>
            <div className="modal">
                <div className="modal-header">
                    <div className="title">
                        <span className="big bold">Ablehnungsgrund</span>
                    </div>
                    <div className="actions">
                        <DetailActionButton name="exit" icon="x" onPress={() => closeModal()} />
                    </div>
                </div>
                <div className="modal-content">
                    <div className="rejection-modal">
                        {mainReasonSelection()}
                        {mainReason && secondaryReasonSelection()}
                    </div>
                    <div className="submit">
                        <DefaultButton text="Abbrechen" isFlat={true} onPress={() => closeModal()} />
                        <DefaultButton text="Ablehnen" loading={loading} onPress={() => submit()} />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}
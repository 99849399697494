import { combineReducers } from 'redux';
import { authentication } from "./authentication";
import { company } from './company';
import { store } from './store';
import { product } from './product';
import { general } from './general';
import { order } from './order';
import { statistic } from './statistic';
// import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  authentication: authentication,
  company: company,
  store: store,
  product: product,
  general: general,
  order: order,
  statistic: statistic
});

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    // document.documentElement.setAttribute('theme', "light");
    // storage.removeItem('persist:authentication');
    state = { company: { urlIdentifier: state.company.urlIdentifier }};
  }
  return appReducer(state, action)
}